<div class="prayer-preview">
  <div class="content">
    <div class="content-leftSide">
      <img
        [attr.src]="prayer.image"
        class="image"
      />
    </div>
    <div class="content-rightSide">
      <div class="content-title">
        <div class="title"><span>{{ prayer.title }}</span></div>
        <div [innerHTML]="getSafeHtml(prayer.story)"></div>
      </div>

      <div class="content-brief-description">
        <div class="description">BRIEF DESCRIPTION:</div>
        <div [innerHTML]="getSafeHtml(prayer.preview_story)"></div>
      </div>

      <div class="content-video-link">
        <div class="description">VIDEO LINK:</div>
        <div>
          <a
            *ngIf="prayer.video_link"
            [attr.href]="prayer.video_link"
          >{{ prayer.video_link }}</a>
        </div>
      </div>

      <div class="content-bottom">
        <div class="content-bottom--item">
          <div class="description">SCHEDULED <br /> DATE & TIME:</div>
          <div class="text">{{ prayer.schedule_date | date: 'dd.MM.yyyy' }}</div>
          <div class="text">{{ prayer.schedule_date | date: 'shortTime'}}</div>
        </div>
        <div class="content-bottom--item">
          <div class="description">POSTED <br />DATE & TIME:</div>
          <div class="text">{{ prayer.creation_date | date: 'dd.MM.yyyy' }}</div>
          <div class="text">{{ prayer.creation_date | date: 'shortTime'}}</div>
        </div>
        <div class="content-bottom--item">
          <div class="description">PRAYERS<br /> COUNTER:</div>
          <div class="text">
            <div>
              <span><i class="icon icon-ios"></i></span>&nbsp;
              <span>{{prayer.counter_ios}} </span>
            </div>
            <div>
              <span><i class="icon icon-android"></i></span>&nbsp;
              <span>{{ prayer.counter_android }}</span>
            </div>
          </div>
        </div>
        <div class="content-bottom--item">
          <div class="description">COMMENTS<br /> COUNTER:</div>
          <div class="text">
            <div>
              <span><i class="icon icon-ios"></i></span>&nbsp;
              <span>{{prayer.ios_comment_counter}}</span>
            </div>
            <div>
              <span><i class="icon icon-android"></i></span>&nbsp;
              <span>{{prayer.android_comment_counter}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <button
      mat-button
      (click)="onToggleCommentStatus($event)"
      class="open-btn primary"
    >{{ prayer.is_comment_enable ? 'Disable' : 'Enable' }} submitting prayers</button>
    <button
      mat-button
      (click)="onToggleFeatureStatus($event)"
      class="open-btn primary featured"
    >Make {{prayer.priority ? 'non-' : '' }}featured</button>
    <button
      mat-button
      (click)="onEdit($event)"
      class="open-btn"
    >Edit</button>
    <button
      mat-button
      (click)="onDelete($event)"
      class="open-btn warn"
    >Delete</button>
  </div>
</div>
