/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./flagged-expressions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../filters/date/date.component.ngfactory";
import * as i3 from "../filters/date/date.component";
import * as i4 from "../services/filter.service";
import * as i5 from "@angular/common";
import * as i6 from "./flagged-expressions.component";
import * as i7 from "../services/flagged-expressions.service";
import * as i8 from "../services/header.service";
var styles_FlaggedExpressionsComponent = [i0.styles];
var RenderType_FlaggedExpressionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FlaggedExpressionsComponent, data: {} });
export { RenderType_FlaggedExpressionsComponent as RenderType_FlaggedExpressionsComponent };
function View_FlaggedExpressionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "card-body-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "card-body-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "card-body-col-text-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 11, "div", [["class", "card-body-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "toolbar mdc-menu-surface--anchor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "button", [["class", "mdc-icon-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMenu($event, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, ":svg:svg", [["fill", "none"], ["height", "32"], ["viewBox", "0 0 32 32"], ["width", "32"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, ":svg:circle", [["cx", "6.4002"], ["cy", "16"], ["fill", "#5EA8FF"], ["r", "3.2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, ":svg:circle", [["cx", "25.6001"], ["cy", "16"], ["fill", "#5EA8FF"], ["r", "3.2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, ":svg:circle", [["cx", "16.0003"], ["cy", "16"], ["fill", "#5EA8FF"], ["r", "3.2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "mdc-menu mdc-menu-surface"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "ul", [["aria-hidden", "true"], ["aria-orientation", "vertical"], ["class", "mdc-list"], ["role", "menu"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "li", [["class", "mdc-list-item"], ["role", "menuitem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [["class", "mdc-list-item__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Delete "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.token; _ck(_v, 4, 0, currVal_0); var currVal_1 = ("menu-surface-" + _v.context.index); _ck(_v, 12, 0, currVal_1); }); }
export function View_FlaggedExpressionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "filters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-date", [], null, [[null, "onselect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onselect" === en)) {
        var pd_0 = (_co.dateFilter($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DateComponent_0, i2.RenderType_DateComponent)), i1.ɵdid(2, 49152, null, 0, i3.DateComponent, [i4.FilterService], null, { onselect: "onselect" }), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "card-header-column"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Flagged expressions "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "card-header-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "card-body card-body--table"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlaggedExpressionsComponent_1)), i1.ɵdid(10, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.list; _ck(_v, 10, 0, currVal_0); }, null); }
export function View_FlaggedExpressionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-flagged-expressions", [], null, null, null, View_FlaggedExpressionsComponent_0, RenderType_FlaggedExpressionsComponent)), i1.ɵdid(1, 245760, null, 0, i6.FlaggedExpressionsComponent, [i7.FlaggedExpressionsService, i8.HeaderService, i4.FilterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FlaggedExpressionsComponentNgFactory = i1.ɵccf("app-flagged-expressions", i6.FlaggedExpressionsComponent, View_FlaggedExpressionsComponent_Host_0, {}, {}, []);
export { FlaggedExpressionsComponentNgFactory as FlaggedExpressionsComponentNgFactory };
