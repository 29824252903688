<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-actions>
  <button 
    mat-raised-button
    class="open-btn"
    [mat-dialog-close]="{ isAccept: false }"
  >{{ getCancelBtnName() }}</button>
  <button 
    mat-raised-button 
    class="open-btn warn"
    [mat-dialog-close]="{ isAccept: true }" 
  >{{ getConfirmBtnName() }}</button>
</div>
