import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LinksService = /** @class */ (function () {
    function LinksService(httpClient) {
        this.httpClient = httpClient;
    }
    LinksService.prototype.getLinks = function () {
        return this.httpClient.get(environment.API + "/links/");
    };
    LinksService.prototype.updateLinks = function (links) {
        return this.httpClient.put(environment.API + "/links/", links);
    };
    LinksService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LinksService_Factory() { return new LinksService(i0.ɵɵinject(i1.HttpClient)); }, token: LinksService, providedIn: "root" });
    return LinksService;
}());
export { LinksService };
