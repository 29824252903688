var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { clone, findIndex } from 'lodash';
import * as queryString from 'querystring';
import { environment } from '../../environments/environment';
import { FilterService } from './filter.service';
import { BaseListService } from '../base-list-component/base-list.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./filter.service";
var CommentsService = /** @class */ (function (_super) {
    __extends(CommentsService, _super);
    function CommentsService(http, filterService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.filterService = filterService;
        return _this;
    }
    /**
     * Is used for initial GET of comments(prayers)
     * @param search search value
     */
    CommentsService.prototype.get = function (search) {
        var _this = this;
        if (search === void 0) { search = ''; }
        var query = queryString.stringify({
            limit: this.limit,
            offset: this.offset,
            dateFrom: this.filterService.activeDateFilterOptions.dateFrom,
            dateTo: this.filterService.activeDateFilterOptions.dateTo,
            search: search,
            status: this.filterService.activeStatusFilters
        });
        return this.http.get(environment.API + "/prayer/comment/?" + query)
            .pipe(map(function (response) {
            _this._data.total = response.total;
            _this._data.list = _this._data.list.concat(response.data);
            _this._list.next(clone(_this._data.list));
            return clone(_this._data.list);
        }));
    };
    /**
     * GET comments for a specific prayer request
     * @param prayerId
     */
    CommentsService.prototype.getByPrayerId = function (prayerId) {
        var _this = this;
        var query = queryString.stringify({
            limit: 999,
            offset: this.offset,
        });
        return this.http.get(environment.API + "/prayer/" + prayerId + "/comment/?" + query)
            .pipe(map(function (response) {
            _this._data.total = response.total;
            _this._data.list = _this._data.list.concat(response.data);
            _this._list.next(clone(_this._data.list));
            return clone(_this._data.list);
        }));
    };
    /**
     * Is used for search from header search field
     * @param search
     */
    CommentsService.prototype.search = function (search) {
        var _this = this;
        var query = queryString.stringify({
            limit: this.limit + this.offset,
            offset: 0,
            dateFrom: this.filterService.activeDateFilterOptions.dateFrom,
            dateTo: this.filterService.activeDateFilterOptions.dateTo,
            search: search,
            status: this.filterService.activeStatusFilters
        });
        return this.http.get(environment.API + "/prayer/comment/?" + query)
            .pipe(map(function (response) {
            _this._data.total = response.total;
            _this._data.list = response.data;
            _this._list.next(clone(_this._data.list));
            return clone(_this._data.list);
        }));
    };
    /**
     * Not actually removes comment, but changes status to blocked
     * @param id - comment id
     */
    CommentsService.prototype.remove = function (id) {
        var _this = this;
        return this.http.patch(environment.API + "/prayer/comment/" + id + "/", { status: 'blocked' })
            .pipe(map(function (response) {
            var index = findIndex(_this._data.list, function (comment) { return comment.id === id; });
            _this._data.list[index] = response;
            _this._list.next(clone(_this._data.list));
        }));
    };
    /**
     * Change status to posted
     * @param id - comment id
     */
    CommentsService.prototype.postComment = function (id) {
        var _this = this;
        return this.http.patch(environment.API + "/prayer/comment/" + id + "/", { status: 'posted' })
            .pipe(map(function (response) {
            var index = findIndex(_this._data.list, function (comment) { return comment.id === id; });
            _this._data.list[index] = response;
            _this._list.next(clone(_this._data.list));
        }));
    };
    CommentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommentsService_Factory() { return new CommentsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FilterService)); }, token: CommentsService, providedIn: "root" });
    return CommentsService;
}(BaseListService));
export { CommentsService };
