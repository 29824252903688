import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { DataService } from './data.service';

@Injectable()
export class AuthGuard implements CanActivate {
  
  constructor(private authService: AuthorizationService, private dataService: DataService) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    return this.authService.isAuthenticated().
      then(
        (authenticated: boolean) => {
          if (authenticated) {
            return true;
          } else {
            this.dataService.logOut('You have to be logged in to access this page.')
          }
        }
      );
  }
}