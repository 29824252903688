import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MDCMenu } from '@material/menu/component';
import * as moment from 'moment';

import {FilterService } from '../../services/filter.service';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent {

  dateRangeLabel = 'Date Range';

  @Output() onselect: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('menu', {static: true}) menu;

  constructor(public readonly filterService: FilterService) { }

  openMenu(): void {
    const menu = new MDCMenu(this.menu.nativeElement);
    menu.open = true;
  }

  select(option): void {
    this.onselect.emit(option);
  }

  onSelectRange(dateRange: Array<Date>): void {
    const [ dateFrom, dateTo ] = dateRange;
    this.dateRangeLabel = `${moment(dateFrom).format('MM.DD.YYYY')} - ${moment(dateTo).format('MM.DD.YYYY')}`;
    const option = {
      name: this.dateRangeLabel,
      dateFrom: moment.utc([ dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate() ])
        .startOf('day')
        .subtract(moment().parseZone().utcOffset(), 'minutes')
        .format(),
      dateTo: moment.utc([ dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate() ])
        .endOf('day')
        .subtract(moment().parseZone().utcOffset(), 'minutes')
        .format(),
    };
    this.select(option);
  }

}
