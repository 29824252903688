import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
var DataService = /** @class */ (function () {
    function DataService(router, http) {
        this.router = router;
        this.http = http;
        this.sideNavStatus = new Subject();
        this.userNotification = new Subject();
        this.prayRequestsList = new Subject();
        this.filteredPrayRequests = new Subject();
        this.API = environment.API;
    }
    DataService.prototype.changeSideNav = function (status) {
        this.sideNavStatus.next(status);
        return this.sideNavStatus.asObservable();
    };
    DataService.prototype.showNotification = function (message, action, durationVal, additionalClass) {
        this.userNotification.next({
            notifyMessage: message,
            notifyAction: action,
            notifyDuration: durationVal,
            class: additionalClass
        });
    };
    DataService.prototype.addPrayRequest = function (data) {
        var accessToken = sessionStorage.getItem('accessToken');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
        });
        return this.http.post(this.API + '/prayers/', data, { headers: headers });
    };
    DataService.prototype.updatePrayRequest = function (data, id) {
        var accessToken = sessionStorage.getItem('accessToken');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
        });
        return this.http.put(this.API + '/prayers/' + id + '/', data, { headers: headers });
    };
    DataService.prototype.getPrayRequests = function () {
        var _this = this;
        var accessToken = sessionStorage.getItem('accessToken');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        });
        this.http.get(this.API + '/prayers/', { headers: headers }).subscribe(function (result) { return _this.prayRequestsList.next(result); }, function (err) {
            console.log('Error', err);
            if (err instanceof Error) {
                console.log('Frontend error');
                _this.showNotification('Unfortunately, there was a problem. Please try again. If the problem continues, get in touch with your developers.', null, 4000, 'error');
                return null;
            }
            else {
                console.log('Backend returned error code ', err.status, '. Error body:', err.message);
                if (err.status === 401) {
                    _this.logOut('You have been logged out. Please input your credentials.');
                    return null;
                }
                else {
                    _this.showNotification('We\'re experiencing some technical difficulties. Please check your internet connection or try again later.', null, 4000, 'error');
                    return null;
                }
            }
        });
    };
    DataService.prototype.logOut = function (message) {
        var _this = this;
        if (message === void 0) { message = 'You have been logged out.'; }
        this.router.navigateByUrl('/authorization').then(function (result) {
            if (!result) {
                return;
            }
            try {
                localStorage.removeItem('refreshToken');
            }
            catch (err) {
                console.log(err);
                _this.showNotification('Critical error: ' + err, null, 4000, 'error');
            }
            try {
                sessionStorage.removeItem('refreshToken');
                sessionStorage.removeItem('accessToken');
            }
            catch (err) {
                console.log(err);
                _this.showNotification('Critical error: ' + err, null, 4000, 'error');
            }
            _this.showNotification(message, null, 4000, 'warning');
        });
    };
    DataService.prototype.deletePray = function (id) {
        var _this = this;
        var accessToken = sessionStorage.getItem('accessToken');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        });
        this.http.delete(this.API + '/prayers/' + id + '/', { headers: headers }).subscribe(function (result) {
            _this.getPrayRequests();
            _this.showNotification('Pray request was deleted successfully.', null, 4000, 'success');
        }, function (err) {
            console.log(err);
            _this.showNotification('Critical error happens. Error: ' + err, null, 6000, 'error');
        });
    };
    DataService.prototype.getPray = function (id) {
        var accessToken = sessionStorage.getItem('accessToken');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        });
        return this.http.get(this.API + '/prayers/' + id + '/', { headers: headers });
    };
    DataService.prototype.changePriority = function (id) {
        var _this = this;
        var accessToken = sessionStorage.getItem('accessToken');
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        });
        this.http.put(this.API + '/change_priority/' + id + '/', null, { headers: headers }).subscribe(function (result) {
            _this.getPrayRequests();
            _this.showNotification('Pray request\'s priority was successfully changed.', null, 4000, 'success');
        }, function (err) {
            console.log(err);
            _this.showNotification('Critical error happens. Error status: ' + err.status, null, 6000, 'error');
        });
    };
    DataService.prototype.filterPrayRequests = function (value) {
        this.filteredPrayRequests.next(value);
    };
    DataService.prototype.getEmails = function () {
        var _this = this;
        var accessToken = sessionStorage.getItem('accessToken');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
        });
        this.http.get(this.API + '/emails/', { headers: headers, responseType: 'blob' }).subscribe(function (result) {
            var blob = new Blob([result], { type: 'text/csv' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'open_doors_clients_emails.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            _this.showNotification('E-mails exported successfully.', null, 4000, 'success');
        }, function (err) {
            console.log(err);
            _this.showNotification('Critical error happens. Error status: ' + err.status, null, 6000, 'error');
        });
    };
    DataService.prototype.getPrivacyPolicy = function () {
        var accessToken = sessionStorage.getItem('accessToken');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
        });
        return this.http.get(this.API + '/privacy-policy/', { headers: headers });
    };
    DataService.prototype.getTermsOfService = function () {
        var accessToken = sessionStorage.getItem('accessToken');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
        });
        return this.http.get(this.API + '/terms-of-service/', { headers: headers });
    };
    DataService.prototype.updateLegal = function (name, content) {
        var accessToken = sessionStorage.getItem('accessToken');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
        });
        var type = name === 'Privacy Policy' ? 'privacy-policy' : 'terms-of-service';
        var data = {
            'type': type,
            'text': content
        };
        return this.http.post(this.API + '/docs/', data, { headers: headers });
    };
    DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient)); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
