import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { HeaderService } from '../../services/header.service';
import { FlaggedExpressionsService } from '../../services/flagged-expressions.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-flagged-expressions-new',
  templateUrl: './flagged-expressions-new.component.html',
  styleUrls: ['./flagged-expressions-new.component.scss']
})
export class FlaggedExpressionsNewComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  private subs: Subscription;

  constructor(private readonly headerService: HeaderService,
              private readonly flaggedService: FlaggedExpressionsService,
              private readonly router: Router) {
  }

  ngOnInit() {
    this.subs = new Subscription();

    this.form = new FormGroup({
      token: new FormControl('', [Validators.required])
    });

    this.subs.add(
      this.headerService.saveObservable.subscribe(() => {
        this.save();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  save(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.flaggedService.create(this.form.value).subscribe( response => {
        this.router.navigate(['/flagged-expressions']);
      });
    }
  }
}
