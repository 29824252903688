import { EventEmitter, OnInit } from '@angular/core';
import { MDCMenu } from '@material/menu/component';
import { FilterService } from '../../services/filter.service';
var StatusComponent = /** @class */ (function () {
    function StatusComponent(filterService) {
        this.filterService = filterService;
        this.onselect = new EventEmitter();
    }
    StatusComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedStatuses = {};
        this.filterService.getStatuses().subscribe();
        this.filterService.statuses.subscribe(function (statuses) {
            _this.statuses = statuses;
        });
    };
    StatusComponent.prototype.openMenu = function () {
        var menu = new MDCMenu(this.menu.nativeElement);
        menu.open = true;
    };
    StatusComponent.prototype.select = function () {
        this.onselect.emit(Object.keys(this.selectedStatuses));
    };
    StatusComponent.prototype.selectOption = function (event, option) {
        event.preventDefault();
        event.stopPropagation();
        if (this.selectedStatuses[option]) {
            delete this.selectedStatuses[option];
        }
        else {
            this.selectedStatuses[option] = true;
        }
        this.onselect.emit(Object.keys(this.selectedStatuses));
    };
    Object.defineProperty(StatusComponent.prototype, "selected", {
        get: function () {
            return !!Object.keys(this.selectedStatuses).length;
        },
        enumerable: true,
        configurable: true
    });
    return StatusComponent;
}());
export { StatusComponent };
