import { OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { MDCMenu } from '@material/menu/component';

import { BaseListService } from './base-list.service';
import { HeaderService } from '../services/header.service';
import { FilterService } from '../services/filter.service';

export abstract class BaseListComponent implements OnInit, OnDestroy {
  public list: any[];
  protected subs: Subscription;
  private openedMenu: MDCMenu;

  constructor(private readonly _baseListService: BaseListService,
              private readonly _headerService: HeaderService,
              private readonly _filterService: FilterService) {
  }

  ngOnInit(): void {
    this.subs = new Subscription();
    this._baseListService.get().subscribe();

    const main = document.querySelector('main');

    // todo: make separate pagination for safari
    this.subs.add(
      fromEvent(main, 'scroll', {passive: true}).pipe(
        filter(() => this.list.length < this._baseListService.total),
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe(() => {
        // if scrollBottom is less than 300px
        if (this.scrollBottom < 300) {
          this._baseListService.addPage();
          this._baseListService.get(this._headerService.searchValue).subscribe( () => {
          });
        }
      })
    );

    this.subs.add(
      this._baseListService.list.subscribe(list => {
        this.list = list;
      })
    );
  }


  private get scrollBottom() {
    const main = document.querySelector('main');
    const card = document.querySelector('.card');
    return card.clientHeight - (main.scrollTop + main.clientHeight);
  }

  ngOnDestroy(): void {
    this._baseListService.reset();
    this._headerService.resetSearch();
    this.subs.unsubscribe();
  }

  dateFilter(event): void {
    this._filterService.changeDateFilterOption(event);
    this._baseListService.search(this._headerService.searchValue).subscribe();
  }

  openMenu(event, index: number): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.openedMenu) {
      this.openedMenu.destroy();
    }
    this.openedMenu = new MDCMenu(document.querySelector(`#menu-surface-${index}`));
    this.openedMenu.open = true;
  }

  remove(event, item): void {
    event.stopPropagation();
    event.preventDefault();

    this._baseListService.remove(item.id).subscribe();
  }
}
