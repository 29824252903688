import { Injectable } from '@angular/core';
import { PrayersService } from './prayers.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, skip} from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { CommentsService } from './comments.service';
import { environment } from '../../environments/environment';
import { DataService } from '../data.service';
import { FlaggedExpressionsService } from './flagged-expressions.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private _keyword = '';
  private _save: BehaviorSubject<boolean>;
  private _keywordObservable: BehaviorSubject<string>;
  private readonly _savePause: Subject<boolean>;
  private _isSavingPaused = false;

  public get searchValue() {
    return this._keyword;
  }

  public save() {
    this._save.next(true);
  }

  public get saveObservable(): Observable<boolean> {
    return this._save.asObservable().pipe(
      skip(1),
      filter(() => this.canSave)
    );
  }

  public blockSaving() {
    this._isSavingPaused = true;
  }

  public unblockSaving() {
    this._isSavingPaused = false;
  }

  public get canSave(): boolean {
    return !this._isSavingPaused;
  }

  public set searchValue(value) {
    this._keyword = value;
    this.prayersService.search(this._keyword).subscribe();
  }

  public set commentSearch(value) {
    this._keyword = value;
    this.commentsService.search(this._keyword).subscribe();
  }

  public set expressionSearch(value) {
    this._keyword = value;
    this.expressionsService.search(this._keyword).subscribe();
  }

  public resetSearch() {
    this._keyword = '';
  }

  public get keyword(): Observable<string> {
    return this._keywordObservable.asObservable();
  }

  constructor(private prayersService: PrayersService,
              private readonly http: HttpClient,
              private readonly commentsService: CommentsService,
              private readonly expressionsService: FlaggedExpressionsService,
              private readonly dataService: DataService) {
    this._save = new BehaviorSubject(true);
    this._keywordObservable = new BehaviorSubject('');
    this._savePause = new Subject();
  }

  exportComments(): void {
    this.http.get(`${environment.API}/statistic/`, {responseType: 'blob'}).subscribe(
      (result) => {
        const blob = new Blob([result], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'prayers.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.dataService.showNotification('Prayers exported successfully.', null, 4000, 'success');
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.dataService.showNotification('Critical error happened. Error status: ' + err.status, null, 6000, 'error');
      }
    );
  }
}
