import {
  HttpEvent,
  HttpHandler,
  HttpResponse,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { AuthorizationService } from './authorization.service';
import { DataService } from './data.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthorizationService, private dataService: DataService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('refresh-token') || req.url.includes('token')) {
      return next.handle(req);
    } else {
      const token = sessionStorage.getItem('accessToken');
      const tokenExpires = jwt_decode(token).exp;
      const currentTimeStamp = new Date().getTime() / 1000;
      if (tokenExpires - currentTimeStamp < 10) {
        return new Observable<HttpEvent<any>>(subscriber => {
          this.authService.refreshToken().subscribe(
            (result: any) => {
              try {
                const newAccessToken = result.access;
                const newRefreshToken = result.refresh;
                const modifiedAccessToken = 'Bearer ' + result.access;
                sessionStorage.setItem('refreshToken', newRefreshToken);
                sessionStorage.setItem('accessToken', newAccessToken);
                localStorage.getItem('refreshToken') ? localStorage.setItem('refreshToken', newRefreshToken) : null;
                localStorage.getItem('refreshToken') ? localStorage.setItem('refreshToken', newRefreshToken) : null;
                const copiedReq = req.clone({headers: req.headers.set('Authorization', modifiedAccessToken)});
                next.handle(copiedReq).subscribe(newEvent => {
                  if (newEvent instanceof HttpResponse) {
                    subscriber.next(newEvent);
                    subscriber.complete();
                  }
                }, error => {
                  this.dataService.showNotification('Critical error: ' + error, null, 4000, 'error');
                });
              } catch (err) {
                this.dataService.showNotification('Critical error: ' + err, null, 4000, 'error');
              }
            },
            (err: HttpErrorResponse) => {

            }
          );
        });
      } else {
        return next.handle(req);
      }
    }
  }
}
