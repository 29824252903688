import { Component, OnInit, HostListener } from '@angular/core';
import { DataService } from '../data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { take, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { CanComponentDeactivate } from '../models/can-component-deactivate.interface';
import { ConfirmationDialogService } from '../services';

@Component({
  selector: 'app-agreement-terms',
  templateUrl: './agreement-terms.component.html',
  styleUrls: ['./agreement-terms.component.scss']
})
export class AgreementTermsComponent implements OnInit, CanComponentDeactivate {

  selectedItem;
  options = {
    toolbar: [
      [{'header': [1, 2, 3, 4, 5, 6, false]}],
      [{'size': ['small', false, 'large', 'huge']}],
      ['bold', 'italic', 'underline', 'strike'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link', {'color': []}]
    ]
  };
  initialTerms;
  initialPrivacyPolicy;
  currentTerms;
  currentPrivacyPolicy;
  editorContent;

  @HostListener('window:beforeunload', [ '$event' ])
  onbeforeunload() {
    return !this.isChangesExists();
  }

  constructor(
    private readonly dataService: DataService,
    private readonly confirmationDialogService: ConfirmationDialogService,
  ) {
  }

  ngOnInit() {
    this.dataService.changeSideNav(true);
    this.selectedItem = 'terms';
    this.initialTerms = this.dataService.getTermsOfService().subscribe(
      (result: any) => {
        this.editorContent = this.currentTerms = this.initialTerms = result && result.text ? result.text[0] : '';
      },
      (err: HttpErrorResponse) => {

      }
    );
    this.initialPrivacyPolicy = this.dataService.getPrivacyPolicy().subscribe(
      (result: any) => {
        this.currentPrivacyPolicy = this.initialPrivacyPolicy = result && result.text ? result.text[0] : '';
      },
      (err: HttpErrorResponse) => {
        
      }
    );
  }

  onValChange(value) {
    value == 'privacy_policy' ? this.currentTerms = this.editorContent : this.currentPrivacyPolicy = this.editorContent;
    this.selectedItem = value;
    this.editorContent = value == 'privacy_policy' ? this.currentPrivacyPolicy : this.currentTerms;
  }

  undoChanges() {
    this.editorContent = this.selectedItem == 'privacy_policy' ? this.initialPrivacyPolicy : this.initialTerms;
  }

  submit() {
    const name = this.selectedItem == 'privacy_policy' ? 'Privacy Policy' : 'Terms and Conditions';
    this.dataService.updateLegal(name, this.editorContent).subscribe(
      (result) => {
        this.dataService.showNotification(name + ' has been changed successfully!', null, 4000, 'success');
        this.selectedItem == 'privacy_policy' ? this.initialPrivacyPolicy = this.editorContent : this.initialTerms = this.editorContent;
      },
      (err: HttpErrorResponse) => {
      }
    );
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isChangesExists()) {
      return this.confirmationDialogService.canDiactivateComponentDialog()
        .afterClosed()
        .pipe(
          map((response: { isAccept: boolean }) => {
            return response ? response.isAccept : false;
          }),
          take(1)
        );
    }
    return true;
  }

  private isChangesExists(): boolean {
    return (this.selectedItem === 'privacy_policy' ? this.initialPrivacyPolicy : this.initialTerms) !== this.editorContent;
  }

}
