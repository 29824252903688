/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/ngx-quill/ngx-quill.ngfactory";
import * as i3 from "ngx-quill";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
import * as i7 from "./editor.component";
var styles_EditorComponent = [i0.styles];
var RenderType_EditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditorComponent, data: {} });
export { RenderType_EditorComponent as RenderType_EditorComponent };
export function View_EditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "quill-editor", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], null, null, i2.View_QuillEditorComponent_0, i2.RenderType_QuillEditorComponent)), i1.ɵdid(1, 4898816, null, 0, i3.QuillEditorComponent, [i1.ElementRef, i4.DomSanitizer, i5.DOCUMENT, i1.PLATFORM_ID, i1.Renderer2, i1.NgZone, i3.QUILL_CONFIG_TOKEN], { modules: [0, "modules"] }, null), i1.ɵprd(1024, null, i6.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.QuillEditorComponent]), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.QuillEditorComponent]), i1.ɵdid(4, 540672, null, 0, i6.FormControlDirective, [[6, i6.NG_VALIDATORS], [8, null], [6, i6.NG_VALUE_ACCESSOR], [2, i6.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i6.NgControl, null, [i6.FormControlDirective]), i1.ɵdid(6, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.options; _ck(_v, 1, 0, currVal_7); var currVal_8 = _co.control; _ck(_v, 4, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_EditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-editor", [], null, null, null, View_EditorComponent_0, RenderType_EditorComponent)), i1.ɵdid(1, 114688, null, 0, i7.EditorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditorComponentNgFactory = i1.ɵccf("app-editor", i7.EditorComponent, View_EditorComponent_Host_0, { control: "control" }, {}, []);
export { EditorComponentNgFactory as EditorComponentNgFactory };
