<form [formGroup]="form">
  <div class="app-update-links">
    <div class="card">
      <div class="card-header">
        <div class="card-header-colomn">
          <span class="title">Menu item</span>
        </div>
        <div class="card-header-colomn">
          <span class="title">Web link</span>
        </div>
      </div>
      <div class="card-body">
        <div
          *ngFor="let control of getFormControls()" 
          class="card-body-row"
        >
          <div class="card-body-col">
            <div class="form-control mdc-text-field mdc-text-field--outlined mdc-text-field--no-label">
              <input 
                type="text"
                class="input mdc-text-field__input"
                formControlName="{{ getFormControlName(control) }}"
                readonly
              />
            </div>
            <div class="label">*Non editable</div>
          </div>
          <div class="card-body-col">
            <div class="form-control mdc-text-field mdc-text-field--outlined mdc-text-field--no-label"
              [ngClass]="{ 'error': form.get(getFormControlName(control, 'value')).invalid && form.get(getFormControlName(control, 'value')).touched }"
            >
              <input 
                type="text"
                placeholder="Put the link here"
                class="input mdc-text-field__input"
                formControlName="{{ getFormControlName(control, 'value') }}"
              />
            </div>
            <div 
              *ngIf="form.get(getFormControlName(control, 'value')).touched"
              class="validation-msg"
            >
              <span
                *ngIf="form.get(getFormControlName(control, 'value')).invalid && form.get(getFormControlName(control, 'value')).errors.required"
              >
                This field is required!
              </span>
              <span
                *ngIf="form.get(getFormControlName(control, 'value')).invalid && form.get(getFormControlName(control, 'value')).errors.pattern"
                class="label-group_validation-msg"
              >
                Please, enter valid url!
              </span>
            </div>
            <div class="label">*Required</div>
          </div>
        </div>
      </div>
    </div>
  </div>      
</form>
