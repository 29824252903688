import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../environments/environment';

if (environment.production) {
  Sentry.init({
    dsn: 'https://cd188406b0c1404692b9f5cfb94f9c33@sentry.io/1476116',
    environment: environment.env
  });
}

@Injectable()
export class SentryService implements ErrorHandler {
  constructor() {
  }

  handleError(error) {
    if (environment.production) {
      const eventId = Sentry.captureException(error.originalError || error);
    } else {
      console.error(error) ;
    }
  }
}
