import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataService } from './data.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  title = 'Open Doors Admin Panel';
  background: boolean;

  @ViewChild('sidenav', {static: true}) public sidenav;

  constructor(private dataService: DataService, public snackBar: MatSnackBar, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.dataService.userNotification.subscribe(
      (response: any) => {
        this.openSnackBar(response.notifyMessage, response.notifyAction, response.notifyDuration, response.class);
      }
    );
  }

  changeBackground(value: boolean) {
    this.background = !value;
  }

  openSnackBar(message: string, action: string, durationVal: number, additionalClass: string) {
    this.snackBar.open(message, action, {
      duration: durationVal,
      panelClass: additionalClass
    });
  }

}
