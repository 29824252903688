var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FlaggedExpressionsService } from '../services/flagged-expressions.service';
import { HeaderService } from '../services/header.service';
import { BaseListComponent } from '../base-list-component/base-list.component';
import { FilterService } from '../services/filter.service';
var FlaggedExpressionsComponent = /** @class */ (function (_super) {
    __extends(FlaggedExpressionsComponent, _super);
    function FlaggedExpressionsComponent(flaggedService, header, filter) {
        var _this = _super.call(this, flaggedService, header, filter) || this;
        _this.flaggedService = flaggedService;
        _this.header = header;
        _this.filter = filter;
        return _this;
    }
    return FlaggedExpressionsComponent;
}(BaseListComponent));
export { FlaggedExpressionsComponent };
