import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { HeaderService } from '../services/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public searchForm: FormControl;

  private subs: Subscription;

  constructor(private headerService: HeaderService) {
  }

  ngOnInit() {
    this.subs = new Subscription();
    this.searchForm = new FormControl('');
    this.subs.add(
      this.searchForm.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(value => {
        if (/^(\/prayers)$/.test(location.pathname)) {
          this.headerService.searchValue = value;
        } else if (/^(\/comments)$/.test(location.pathname)) {
          this.headerService.commentSearch = value;
        } else if (/^(\/flagged-expressions)$/.test(location.pathname)) {
          this.headerService.expressionSearch = value;

        }
      })
    );
  }

  ngAfterViewInit(): void {
  }


  saveHandler(): void {
    this.headerService.save();
  }


  // todo make a service which will control behavior of header components
  public get showCreate(): boolean {
    return /^(\/prayers)$/.test(location.pathname);
  }

  public get showSave(): boolean {
    return /^(\/links|\/prayers\/new|\/prayers\/edit\/[0-9]+|\/flagged-expressions\/new)$/.test(location.pathname);
  }

  public get showSearch(): boolean {
    return /^(\/prayers|\/comments|\/flagged-expressions)$/.test(location.pathname);
  }

  public get showBtnBack(): boolean {
    return !/^(\/links|\/prayers|\/comments|\/flagged-expressions)$/.test(location.pathname);
  }

  public get showComments(): boolean {
    return /^(\/comments)$/.test(location.pathname);
  }

  public get showCreateExpression(): boolean {
    return /^(\/flagged-expressions)$/.test(location.pathname);
  }

  exportComments(): void {
    this.headerService.exportComments();
  }

  back() {
    window.history.back();
  }
}
