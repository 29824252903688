import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Prayer } from '../models/prayer';

@Component({
  selector: 'app-prayer-preview',
  templateUrl: './prayer-preview.component.html',
  styleUrls: ['./prayer-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrayerPreviewComponent {

  @Input() prayer: Prayer;

  @Output() toggleCommentStatus: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() toggleFeatureStatus: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() edit: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() delete: EventEmitter<Event> = new EventEmitter<Event>();

  constructor(private readonly domSinitizer: DomSanitizer) {}

  onToggleCommentStatus(event: Event): void {
    this.toggleCommentStatus.emit(event);
  }

  onToggleFeatureStatus(event: Event): void {
    this.toggleFeatureStatus.emit(event);
  }

  onEdit(event: Event): void {
    this.edit.emit(event);
  }

  onDelete(event: Event): void {
    this.delete.emit(event);
  }

  getSafeHtml(content: string): SafeHtml {
    return this.domSinitizer.bypassSecurityTrustHtml(content);
  }
}
