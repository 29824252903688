import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, of } from "rxjs";
import { take, catchError, map } from "rxjs/operators";

import { LinksService, LinkInterface } from "src/app/services/links.service";

const DEFAULT_LINKS: LinkInterface = {
    'Ga mee': null,
    'Volg ons': null,
    'Over ons': null,
    'Geef': null,
    'Magazine aanvragen': null,
};
@Injectable({
    providedIn: 'root'
})
export class UpdateLinkMenuResolver implements Resolve<Observable<LinkInterface>> {

    constructor(private readonly linksService: LinksService) {}

    resolve(): Observable<LinkInterface> {
        return this.linksService.getLinks()
            .pipe(
                map((response: { [name: string]: string }) => ({ ...DEFAULT_LINKS, ...response })),
                take(1),
                catchError(() => of(DEFAULT_LINKS)),
            );
    }

}
