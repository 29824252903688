import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export abstract class BaseListService {
  protected _data: {
    list: any[],
    total: number
  };

  protected readonly _list: BehaviorSubject<any[]>;
  protected offset = 0;
  public readonly limit = 20;

  constructor() {
    this._data = {
      list: [],
      total: 0
    };

    this._list = new BehaviorSubject([]);
  }

  public get total(): number {
    return this._data.total;
  }

  public get list(): Observable<any[]> {
    return this._list.asObservable();
  }

  /**
   * Adds offset equal to limit
   */
  public addPage(): void {
    this.offset += this.limit;
  }

  public reset(): void {
    this.offset = 0;
    this._data.list = [];
  }

  abstract get(search?: string): Observable<any[]>;
  abstract search(search: string): Observable<any[]>;
  abstract remove(id: number): Observable<any>;
}
