<div class="toolbar mdc-menu-surface--anchor">
  <button class="mdc-menu-anchor" [ngClass]="{'mdc-menu-anchor--active': selected}" (click)="openMenu()">
    <i>
      <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9086 18H15.2564V16H10.9086V18ZM3.29993 6V8H22.8651V6H3.29993ZM6.5608 13H19.6043V11H6.5608V13Z"></path>
      </svg>
    </i>
    <span>
      Filter
    </span>
  </button>

  <div class="mdc-menu mdc-menu-surface" #menu>
    <ul class="mdc-list"
        role="menu"
        aria-hidden="true"
        aria-orientation="vertical"
        tabindex="-1">
      <li class="mdc-list-item"
          role="menuitem"
          (click)="selectOption($event, option)"
          *ngFor="let option of statuses; index as i">
        <div class="mdc-list-item-overlay"></div>
        <div class="mdc-form-field">
          <div class="mdc-checkbox">
            <input type="checkbox"
                   class="mdc-checkbox__native-control"
                   [checked]="selectedStatuses[option] && 'checked'"
                   [id]="'checkbox-' + i"/>
            <div class="mdc-checkbox__background">
              <svg class="mdc-checkbox__checkmark"
                   viewBox="0 0 24 24">
                <path class="mdc-checkbox__checkmark-path"
                      fill="none"
                      d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
              </svg>
              <div class="mdc-checkbox__mixedmark"></div>
            </div>
          </div>
          <label [for]="'checkbox-' + i">{{option}}</label>
        </div>
      </li>
    </ul>
  </div>

</div>
