import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FilterService } from './filter.service';
import { Prayer } from '../models/prayer';
import * as queryString from 'querystring';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { clone, findIndex } from 'lodash';

import { BaseListService } from '../base-list-component/base-list.service';

@Injectable({
  providedIn: 'root'
})
export class FlaggedExpressionsService extends BaseListService {

  constructor(private readonly http: HttpClient,
              private readonly filterService: FilterService) {
    super();
  }


  /**
   * GET list of prayers
   * @param search search query
   */
  public get(search = ''): Observable<Prayer[]> {
    const query = queryString.stringify({
      limit: this.limit,
      offset: this.offset,
      dateFrom: this.filterService.activeDateFilterOptions.dateFrom,
      dateTo: this.filterService.activeDateFilterOptions.dateTo,
      search: search
    });
    return this.http.get(`${environment.API}/abusive-word/?${query}`)
      .pipe(
        map((response: any) => {
          this._data.total = response.total;
          this._data.list = [...this._data.list, ...response.data];

          this._list.next(clone(this._data.list));
          return clone(this._data.list);
        })
      );
  }

  public search(search): Observable<Prayer[]> {
    const query = queryString.stringify({
      limit: this.limit + this.offset,
      offset: 0,
      dateFrom: this.filterService.activeDateFilterOptions.dateFrom,
      dateTo: this.filterService.activeDateFilterOptions.dateTo,
      search: search
    });
    return this.http.get(`${environment.API}/abusive-word/?${query}`)
      .pipe(
        map((response: any) => {
          this._data.total = response.total;
          this._data.list = response.data;

          this._list.next(clone(this._data.list));
          return clone(this._data.list);
        })
      );
  }

  /**
   * Remove prayer with 'id'
   * @param id id of prayer
   */
  public remove(id: number): Observable<any> {
    return this.http.delete(`${environment.API}/abusive-word/${id}/`).pipe(
      map((response: any) => {
        const index = findIndex(this._data.list, prayer => prayer.id === id);
        this._data.total--;
        this._data.list.splice(index, 1);

        this._list.next(clone(this._data.list));
        return clone(this._data.list);
      })
    );
  }

  /**
   * Create new flagged expression
   */
  public create(payload): Observable<any> {
    return this.http.post(`${environment.API}/abusive-word/`, payload);
  }
}
