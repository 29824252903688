import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';


@NgModule({
  exports: [MatToolbarModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatSidenavModule, MatIconModule, MatExpansionModule, MatSelectModule, MatSnackBarModule, MatBadgeModule, MatButtonToggleModule]
})

export class AppMaterialModule {

}
