import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { clone, findIndex } from 'lodash';
import * as queryString from 'querystring';
import { HttpClient } from '@angular/common/http';

import { Prayer } from '../models/prayer';
import { environment } from '../../environments/environment';
import { FilterService } from './filter.service';
import { BaseListService } from '../base-list-component/base-list.service';

@Injectable({
  providedIn: 'root'
})
export class PrayersService extends BaseListService {
  constructor(private http: HttpClient, private readonly filterService: FilterService) {
    super();
  }

  /**
   * GET list of prayers
   * @param search search query
   */
  public get(search = ''): Observable<Prayer[]> {
    const query = queryString.stringify({
      limit: this.limit,
      offset: this.offset,
      dateFrom: this.filterService.activeDateFilterOptions.dateFrom,
      dateTo: this.filterService.activeDateFilterOptions.dateTo,
      search: search
    });
    return this.http.get(`${environment.API}/prayers/?${query}`)
      .pipe(
        map((response: any) => {
          this._data.total = response.total;
          this._data.list = [...this._data.list, ...response.data];

          this._list.next(clone(this._data.list));
          return clone(this._data.list);
        })
      );
  }

  public search(search): Observable<Prayer[]> {
    const query = queryString.stringify({
      limit: this.limit + this.offset,
      offset: 0,
      dateFrom: this.filterService.activeDateFilterOptions.dateFrom,
      dateTo: this.filterService.activeDateFilterOptions.dateTo,
      search: search
    });
    return this.http.get(`${environment.API}/prayers/?${query}`)
      .pipe(
        map((response: any) => {
          this._data.total = response.total;
          this._data.list = response.data;

          this._list.next(clone(this._data.list));
          return clone(this._data.list);
        })
      );
  }

  /**
   * Make 'priority' of prayer with chosen 'id' true or false
   * @param id id of prayer
   */
  public toggleFeatured(id: number): Observable<any> {
    return this.http.put(`${environment.API}/prayer/${id}/change_priority/`, '').pipe(
      map((response: any) => {

      })
    );
  }

  /**
   * Toggle 'is_comment_enable' of prayer true or false
   * @param id [number] - prayer id
   * @param isCommentEnable [boolean]
   */
  public togglePrayerCommentStatus(id: number, isCommentEnable: boolean): Observable<any> {
    return this.http.patch(`${environment.API}/prayers/${id}/`, {
      is_comment_enable: isCommentEnable
    });
  }

  /**
   * Remove prayer with 'id'
   * @param id id of prayer
   */
  public remove(id: number): Observable<any> {
    return this.http.delete(`${environment.API}/prayers/${id}/`).pipe(
      map((response: any) => {
        const index = findIndex(this._data.list, prayer => prayer.id === id);
        this._data.total--;
        this._data.list.splice(index, 1);

        this._list.next(clone(this._data.list));
        return clone(this._data.list);
      })
    );
  }
}
