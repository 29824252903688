import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { MDCRipple } from '@material/ripple';

@Component({
  selector: 'app-sidenav-controls',
  templateUrl: './sidenav-controls.component.html',
  styleUrls: ['./sidenav-controls.component.scss']
})
export class SidenavControlsComponent implements OnInit, AfterViewInit {
  @ViewChild('userEmails', {static: true}) userEmailsButton: ElementRef;

  constructor(private dataService: DataService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    const buttonRipple = new MDCRipple(this.userEmailsButton.nativeElement);
  }

  logOut() {
    this.dataService.logOut();
  }

  getEmails() {
    this.dataService.getEmails();
  }

}
