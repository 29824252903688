import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, take, map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { isEqual } from 'lodash';

import { HeaderService } from '../services/header.service';
import { LinksService, LinkInterface } from '../services/links.service';
import { DataService } from '../data.service';
import { CanComponentDeactivate } from '../models/can-component-deactivate.interface';
import { ConfirmationDialogService } from '../services';

interface FormControlInterface {
  [key: string]: AbstractControl;
}

const urlPattern: string = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/.*';

@Component({
  selector: 'app-update-links',
  templateUrl: './update-links.component.html',
  styleUrls: ['./update-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateLinksComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  form: FormGroup;

  @HostListener('window:beforeunload', [ '$event' ])
  onbeforeunload() {
    return !this.isChangesExists();
  }

  private links: LinkInterface;
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly headerService: HeaderService,
    private readonly dataService: DataService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
    private readonly confirmationDialogService: ConfirmationDialogService,
    private readonly linksService: LinksService,
  ) { }

  ngOnInit(): void {
    this.links = { ...this.activatedRoute.snapshot.data.links as LinkInterface };
    this.form = new FormGroup({
      ...this.getFormControlsLabel(Object.keys(this.links)),
      ...this.getFormControlsValue(this.links),
    });

    this.headerService.unblockSaving();
    this.headerService
      .saveObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.onSubmit(this.form);
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFormControlName(name: string, preffix?: string): string {
    return `${name.trim().toLocaleLowerCase().split(' ').join('_')}${ preffix ? '_' + preffix : '' }`;
  }

  getFormControls(): Array<string> {
    return Object.keys(this.links);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isChangesExists()) {
      return this.confirmationDialogService.canDiactivateComponentDialog()
        .afterClosed()
        .pipe(
          map((response: { isAccept: boolean }) => {
            return response ? response.isAccept : false;
          }),
          take(1)
        );
    }
    return true;
  }

  private isChangesExists(): boolean {
    return !isEqual(this.links, this.getDataForUpdate(this.links, this.form.value));
  }

  private onSubmit(form: FormGroup): void {
    form.markAllAsTouched();

    if (form.valid) {
      this.headerService.blockSaving();
      this.linksService
        .updateLinks(this.getDataForUpdate(this.links, form.value))
        .pipe(take(1))
        .subscribe(
          () => {
            this.links = { ...this.getDataForUpdate(this.links, form.value) };
            this.dataService.showNotification('Links has been updated successfully!', null, 4000, 'success');
            this.headerService.unblockSaving();
          },
          () => {
            this.headerService.unblockSaving();
          }
        );
    }
  }

  private getFormControlsLabel(keys: Array<string>): FormControlInterface {
    return keys.reduce((acc: FormControlInterface, current: string) => {
      return {
        ...acc, 
        ...{ [this.getFormControlName(current)]: new FormControl(current) }
      };
    }, {});
  }

  private getFormControlsValue(data: Object): FormControlInterface {
    return Object.keys(data).reduce((acc: FormControlInterface, current: string) => {
      return {
        ...acc, 
        ...{ 
          [this.getFormControlName(current, 'value')]: new FormControl(
            data[current],
            [ Validators.required, Validators.pattern(urlPattern) ] 
          )}
      };
    }, {});
  }

  private getDataForUpdate(links: LinkInterface, data: Object): LinkInterface {
    return Object.keys(links).reduce((acc: LinkInterface, item: string) => {
      const propertyValue: string = data[this.getFormControlName(item, 'value')];
      return { ...acc, [item]: propertyValue };
    }, {});
  }
}
