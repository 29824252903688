import {
  HttpEvent,
  HttpHandler,
  HttpResponse,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse, HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { AuthorizationService } from '../authorization.service';
import { DataService } from '../data.service';


@Injectable()
export class CommonInterceptor implements HttpInterceptor {

  constructor(private authService: AuthorizationService, private dataService: DataService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = sessionStorage.getItem('accessToken');
    let contentType = '';
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
          console.error('Backend returned error code ', error.status, '. Error body:', error.message);
          if (error.status === 401) {
            this.dataService.logOut('You have been logged out. Please input your credentials.');
            return;
          } else {
            this.dataService.showNotification('We\'re experiencing some technical difficulties. Please check your internet connection or try again later.', null, 4000, 'error');
          }

        return throwError(error);
      })
    );
  }
}
