var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { map } from 'rxjs/operators';
import { clone, findIndex } from 'lodash';
import * as queryString from 'querystring';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FilterService } from './filter.service';
import { BaseListService } from '../base-list-component/base-list.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./filter.service";
var PrayersService = /** @class */ (function (_super) {
    __extends(PrayersService, _super);
    function PrayersService(http, filterService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.filterService = filterService;
        return _this;
    }
    /**
     * GET list of prayers
     * @param search search query
     */
    PrayersService.prototype.get = function (search) {
        var _this = this;
        if (search === void 0) { search = ''; }
        var query = queryString.stringify({
            limit: this.limit,
            offset: this.offset,
            dateFrom: this.filterService.activeDateFilterOptions.dateFrom,
            dateTo: this.filterService.activeDateFilterOptions.dateTo,
            search: search
        });
        return this.http.get(environment.API + "/prayers/?" + query)
            .pipe(map(function (response) {
            _this._data.total = response.total;
            _this._data.list = _this._data.list.concat(response.data);
            _this._list.next(clone(_this._data.list));
            return clone(_this._data.list);
        }));
    };
    PrayersService.prototype.search = function (search) {
        var _this = this;
        var query = queryString.stringify({
            limit: this.limit + this.offset,
            offset: 0,
            dateFrom: this.filterService.activeDateFilterOptions.dateFrom,
            dateTo: this.filterService.activeDateFilterOptions.dateTo,
            search: search
        });
        return this.http.get(environment.API + "/prayers/?" + query)
            .pipe(map(function (response) {
            _this._data.total = response.total;
            _this._data.list = response.data;
            _this._list.next(clone(_this._data.list));
            return clone(_this._data.list);
        }));
    };
    /**
     * Make 'priority' of prayer with chosen 'id' true or false
     * @param id id of prayer
     */
    PrayersService.prototype.toggleFeatured = function (id) {
        return this.http.put(environment.API + "/prayer/" + id + "/change_priority/", '').pipe(map(function (response) {
        }));
    };
    /**
     * Toggle 'is_comment_enable' of prayer true or false
     * @param id [number] - prayer id
     * @param isCommentEnable [boolean]
     */
    PrayersService.prototype.togglePrayerCommentStatus = function (id, isCommentEnable) {
        return this.http.patch(environment.API + "/prayers/" + id + "/", {
            is_comment_enable: isCommentEnable
        });
    };
    /**
     * Remove prayer with 'id'
     * @param id id of prayer
     */
    PrayersService.prototype.remove = function (id) {
        var _this = this;
        return this.http.delete(environment.API + "/prayers/" + id + "/").pipe(map(function (response) {
            var index = findIndex(_this._data.list, function (prayer) { return prayer.id === id; });
            _this._data.total--;
            _this._data.list.splice(index, 1);
            _this._list.next(clone(_this._data.list));
            return clone(_this._data.list);
        }));
    };
    PrayersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PrayersService_Factory() { return new PrayersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FilterService)); }, token: PrayersService, providedIn: "root" });
    return PrayersService;
}(BaseListService));
export { PrayersService };
