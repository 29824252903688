<header>
  <app-header>

  </app-header>
</header>
<aside>
  <app-sidenav-controls>

  </app-sidenav-controls>
</aside>
<main>
  <router-outlet></router-outlet>
</main>
