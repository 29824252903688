<div class="card">
  <div class="card-header">
    <div class="mdc-tab-bar" role="tablist">
      <div class="mdc-tab-scroller">
        <div class="mdc-tab-scroller__scroll-area">
          <div class="mdc-tab-scroller__scroll-content">
            <button class="mdc-tab"
                    role="tab"
                    aria-selected="false"
                    tabindex="-1"
                    [id]="'tab-' + TABS.INFO"
                    (click)="changeTab(TABS.INFO, 0)">
              <span class="mdc-tab__content">
                <span class="mdc-tab__text-label">Info</span>
              </span>
              <span class="mdc-tab-indicator">
                <span class="mdc-tab-indicator__content mdc-tab-indicator__content--underline"></span>
              </span>
              <span class="mdc-tab__ripple"></span>
            </button>
            <button *ngIf="id"
                    class="mdc-tab"
                    role="tab"
                    aria-selected="false"
                    tabindex="-1"
                    [id]="'tab-' + TABS.COMMENTS"
                    (click)="changeTab(TABS.COMMENTS, 1)">
              <span class="mdc-tab__content">
                <span class="mdc-tab__text-label">Prayers</span>
              </span>
              <span class="mdc-tab-indicator">
                <span class="mdc-tab-indicator__content mdc-tab-indicator__content--underline"></span>
              </span>
              <span class="mdc-tab__ripple"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf="activeTab === TABS.INFO" [ngClass]="{'card-body--info': activeTab === TABS.INFO}">
    <form [formGroup]="form">
      <div class="title-country">
        <div class="prayer-block">
          <h3 class="title">
            Prayer title
          </h3>
          <div class="input">
            <div class="mdc-text-field mdc-text-field--outlined mdc-text-field--no-label"
                 [ngClass]="{'mdc-text-field--error': form.get('title').invalid && form.get('title').touched}">
              <input type="text"
                     placeholder="Put the title here"
                     class="mdc-text-field__input"
                     maxlength="50"
                     formControlName="title">
              <div class="mdc-notched-outline">
                <div class="mdc-notched-outline__leading"></div>
                <div class="mdc-notched-outline__trailing"></div>
              </div>
            </div>
            <div class="mdc-text-field-helper-line">
              <div
                class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg mdc-text-field-helper-text--persistent"
                *ngIf="form.get('title').invalid && form.get('title').touched">
                This field is required!
              </div>
              <div class="mdc-text-field-character-counter">{{form.get('title').value.length}}  / 50</div>
            </div>
          </div>
        </div>

        <div class="prayer-block">
          <h3 class="title">
            Country tag
          </h3>
          <div class="input">
            <div class="mdc-text-field mdc-text-field--outlined mdc-text-field--no-label"
                 [ngClass]="{'mdc-text-field--error': form.get('country').invalid && form.get('country').touched}">
              <input type="text"
                     placeholder="Put the country here"
                     class="mdc-text-field__input"
                     maxlength="150"
                     formControlName="country">
              <div class="mdc-notched-outline">
                <div class="mdc-notched-outline__leading"></div>
                <div class="mdc-notched-outline__trailing"></div>
              </div>
            </div>
            <div class="mdc-text-field-helper-line">
              <div
                class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg mdc-text-field-helper-text--persistent"
                *ngIf="form.get('country').invalid && form.get('country').touched">
                This field is required!
              </div>
              <div class="mdc-text-field-character-counter">{{form.get('country').value?.length || 0}}  / 150</div>
            </div>
          </div>
        </div>
      </div>

      <div class="prayer-block">
        <h3 class="title">
          Prayer text
        </h3>
        <div class="input">
          <app-editor [control]="form.get('story')"></app-editor>
        </div>
      </div>

      <div class="prayer-block">
        <h3 class="title">
          Brief description
        </h3>

        <div class="input">
          <div class="mdc-text-field mdc-text-field--textarea mdc-text-field--no-label"
               [ngClass]="{'mdc-text-field--error': form.get('preview_story').invalid && form.get('preview_story').touched}">
            <div class="mdc-text-field-character-counter">{{form.get('preview_story').value.length}} / 175</div>
            <textarea id="textarea" class="mdc-text-field__input" formControlName="preview_story"
                      placeholder="Put the text here" rows="8" cols="40" maxlength="175"></textarea>
            <div class="mdc-notched-outline">
              <div class="mdc-notched-outline__leading"></div>
              <div class="mdc-notched-outline__trailing"></div>
            </div>
          </div>
          <div class="mdc-text-field-helper-line">
            <div
              class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg mdc-text-field-helper-text--persistent"
              *ngIf="form.get('preview_story').invalid && form.get('preview_story').touched">
              This field is required!
            </div>
          </div>
        </div>
      </div>

      <div class="prayer-block">
        <h3 class="title">
          Video link
        </h3>
        <div class="input">
          <div class="mdc-text-field mdc-text-field--outlined mdc-text-field--no-label">
            <input type="text"
                   placeholder="Put the link here"
                   class="mdc-text-field__input"
                   maxlength="150"
                   formControlName="video_link">
            <div class="mdc-notched-outline">
              <div class="mdc-notched-outline__leading"></div>
              <div class="mdc-notched-outline__trailing"></div>
            </div>
          </div>
          <div class="mdc-text-field-helper-line">
            <div
              class="mdc-text-field-character-counter">
              {{ form.get('video_link').value && form.get('video_link').value.length || 0}}
              / 150
            </div>
          </div>
        </div>
      </div>

      <div class="prayer-block">
        <h3 class="title">
          Publication date and time
        </h3>
        <div class="input">
          <div class="mdc-text-field mdc-text-field--with-leading-icon mdc-text-field--outlined mdc-text-field--no-label"
               [owlDateTimeTrigger]="dateTime">
            <i class="material-icons mdc-text-field__icon">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M17 12.9277H12V17.9277H17V12.9277ZM16 1.92773V3.92773H8V1.92773H6V3.92773H5C3.89 3.92773 3.01 4.82773 3.01 5.92773L3 19.9277C3 21.0277 3.89 21.9277 5 21.9277H19C20.1 21.9277 21 21.0277 21 19.9277V5.92773C21 4.82773 20.1 3.92773 19 3.92773H18V1.92773H16ZM19 19.9277H5V8.92773H19V19.9277Z"
                  fill="black" fill-opacity="0.56"/>
              </svg>
            </i>
            <input
              formControlName="schedule_date"
              name="selectedDate"
              class="mdc-text-field__input"
              [owlDateTime]="dateTime"
              [owlDateTimeTrigger]="dateTime"
              [min]="currentMoment"
              placeholder="Date & Time">
            <owl-date-time
              [hour12Timer]="true"
              (afterPickerOpen)="setCurrentMoment()"
              #dateTime></owl-date-time>
            <div class="mdc-notched-outline">
              <div class="mdc-notched-outline__leading"></div>
              <div class="mdc-notched-outline__trailing"></div>
            </div>
          </div>
          <div class="mdc-text-field-helper-line">
            <div class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent">
              If remains empty, prayer request will be published immediately
            </div>
          </div>
        </div>
      </div>

      <div class="prayer-block">
        <h3 class="title">
          Notifications
        </h3>
        <div>
          <mat-checkbox formControlName="is_urgent">Urgent</mat-checkbox>
        </div>
      </div>
    </form>

    <div class="image" [formGroup]="form">
      <div class="image_part">
        <h3 class="title">Image</h3>
        <div class="container">
          <div class="preview_image_container">
            <img [src]="croppedImage"/>
            <div class="click_emitter" *ngIf="!croppedImage" (click)="triggerClick(inputRef)">
              <div class="pseudo_button">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M78.6207 8.27539H1.37931C0.617931 8.27539 0 8.89194 0 9.6547V70.3444C0 71.1071 0.617931 71.7237 1.37931 71.7237H78.6207C79.3821 71.7237 80 71.1071 80 70.3444V9.6547C80 8.89194 79.3821 8.27539 78.6207 8.27539ZM77.2414 68.965H2.75862V11.034H77.2414V68.965Z" fill="black" fill-opacity="0.3"/>
                  <path d="M22.0688 38.811C26.3047 38.811 29.7502 35.3655 29.7502 31.131C29.7502 26.8938 26.3047 23.4482 22.0688 23.4482C17.833 23.4482 14.3875 26.8938 14.3875 31.1296C14.3875 35.3655 17.833 38.811 22.0688 38.811ZM22.0688 26.2069C24.7833 26.2069 26.9916 28.4165 26.9916 31.1296C26.9916 33.8427 24.7833 36.0524 22.0688 36.0524C19.3543 36.0524 17.1461 33.8441 17.1461 31.131C17.1461 28.4179 19.3543 26.2069 22.0688 26.2069Z" fill="black" fill-opacity="0.3"/>
                  <path d="M9.6547 63.4478C9.97746 63.4478 10.303 63.3347 10.565 63.1043L33.0657 43.2947L47.2754 57.5029C47.8147 58.0422 48.6864 58.0422 49.2257 57.5029C49.765 56.9636 49.765 56.0919 49.2257 55.5526L42.5954 48.9222L55.2588 35.0547L70.7912 49.2933C71.3526 49.8078 72.2257 49.7691 72.7402 49.2078C73.2547 48.6464 73.2175 47.7733 72.6547 47.2588L56.103 32.0864C55.8326 31.8395 55.4726 31.7167 55.1099 31.725C54.7444 31.7416 54.3995 31.9029 54.1526 32.1733L40.6437 46.9691L34.1016 40.4271C33.5857 39.9126 32.7609 39.8864 32.2147 40.3664L8.74297 61.0326C8.17056 61.536 8.11539 62.4078 8.61884 62.9802C8.89194 63.2905 9.27263 63.4478 9.6547 63.4478Z" fill="black" fill-opacity="0.3"/>
                </svg>
              </div>
            </div>
          </div>
          <input type="file" accept="image/jpeg, image/png, image/webp" #inputRef (change)="fileChangeEvent($event)"/>
          <div class="mdc-text-field-helper-line">
            <div
              class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg mdc-text-field-helper-text--persistent"
              *ngIf="form.get('image').invalid && form.get('image').touched">
              You need to upload an image!
            </div>
          </div>
          <mat-form-field floatLabel="never">
            <input
              name="image"
              formControlName="image"
              required
              matInput
              type="text">
          </mat-form-field>

          <div class="button_row">
            <button *ngIf="!cropperIsOpen" class="mdc-button mdc-button--raised" (click)="triggerClick(inputRef)">
              <i class="" aria-hidden="true">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.0029 7.38916V10.3792C19.0029 10.3792 17.0129 10.3892 17.0029 10.3792V7.38916H14.0029C14.0029 7.38916 14.0129 5.39916 14.0029 5.38916H17.0029V2.38916H19.0029V5.38916H22.0029V7.38916H19.0029ZM16.0029 11.3892V8.38916H13.0029V5.38916H5.00293C3.90293 5.38916 3.00293 6.28916 3.00293 7.38916V19.3892C3.00293 20.4892 3.90293 21.3892 5.00293 21.3892H17.0029C18.1029 21.3892 19.0029 20.4892 19.0029 19.3892V11.3892H16.0029ZM5.00293 19.3892L8.00293 15.3892L10.0029 18.3892L13.0029 14.3892L17.0029 19.3892H5.00293Z"
                    fill="white"/>
                </svg>
              </i>
              <span class="mdc-button__label">Upload image</span>
            </button>
            <button *ngIf="cropperIsOpen" class="mdc-button mdc-button--raised" (click)="cropImage(inputRef)">
              <i class="" aria-hidden="true">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M21.0971 15.6386H18.55V5.96192V5.45033H8.36134V2.90316C8.36134 2.79693 8.32728 2.70967 8.25898 2.6416C8.19075 2.57346 8.10365 2.53925 7.99739 2.53925H5.8142C5.70806 2.53925 5.62072 2.5733 5.55265 2.6416C5.4845 2.70983 5.45029 2.79705 5.45029 2.90316V5.45033H2.90312C2.79693 5.45033 2.70971 5.48434 2.64156 5.55261C2.57346 5.62084 2.53925 5.70806 2.53925 5.81416V7.99747C2.53925 8.10365 2.5733 8.19087 2.64152 8.25894C2.70979 8.32708 2.79705 8.3613 2.90308 8.3613H5.45025V18.1862C5.45025 18.2923 5.4843 18.3796 5.55261 18.4475C5.62084 18.5156 5.70806 18.55 5.81416 18.55H15.6389V21.0971C15.6389 21.2032 15.673 21.2904 15.7411 21.3585C15.8091 21.4267 15.8964 21.4607 16.0028 21.4607H18.186C18.2922 21.4607 18.3794 21.4267 18.4474 21.3585C18.5156 21.2904 18.5499 21.2032 18.5499 21.0971V18.55H21.097C21.2031 18.55 21.2903 18.5156 21.3583 18.4475C21.4265 18.3796 21.4606 18.2923 21.4606 18.1862V16.0028C21.4606 15.8965 21.4265 15.8091 21.3583 15.7411C21.2904 15.673 21.2032 15.6386 21.0971 15.6386ZM15.6681 8.43188H8.33185V15.5681H15.6681V8.43188Z"
                        fill="#FFFFFF"/>
                </svg>
              </i>
              <span class="mdc-button__label">Crop image</span>
            </button>
          </div>
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="352 / 352"
            [resizeToWidth]="352"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
          ></image-cropper>
        </div>
      </div>

    </div>
  </div>

  <div class="card-body" *ngIf="activeTab === TABS.COMMENTS">
    <app-comments></app-comments>
  </div>
</div>

