<div class="filters">
  <app-date (onselect)="dateFilter($event)">

  </app-date>
</div>
<div class="card">
  <div class="card-header">
    <div class="card-header-column">
      Flagged expressions
    </div>
    <div class="card-header-column">
    </div>
  </div>

  <div class="card-body card-body--table">
    <div class="card-body-row" *ngFor="let word of list; index as i">
      <div class="card-body-col">
        <div class="card-body-col-text-wrap">
          <span>
            {{word.token}}
          </span>
        </div>
      </div>

      <div class="card-body-col">
        <div class="toolbar mdc-menu-surface--anchor">
          <button class="mdc-icon-button" (click)="openMenu($event, i)">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="6.4002" cy="16" r="3.2" fill="#5EA8FF"/>
              <circle cx="25.6001" cy="16" r="3.2" fill="#5EA8FF"/>
              <circle cx="16.0003" cy="16" r="3.2" fill="#5EA8FF"/>
            </svg>
          </button>

          <div class="mdc-menu mdc-menu-surface" [id]="'menu-surface-' + i">
            <ul class="mdc-list" role="menu" aria-hidden="true" aria-orientation="vertical" tabindex="-1">
              <li class="mdc-list-item" role="menuitem"
                  (click)="remove($event, word)">
                <span class="mdc-list-item__text">
                  Delete
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
