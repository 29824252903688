import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MDCMenu } from '@material/menu/component';

import { FilterService } from '../../services/filter.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  public statuses: string[];
  @Output() onselect: EventEmitter<string[]>;
  @ViewChild('menu', {static: true}) menu;
  selectedStatuses: any;
  constructor(private readonly filterService: FilterService) {
    this.onselect = new EventEmitter();
  }

  ngOnInit() {
    this.selectedStatuses = {};
    this.filterService.getStatuses().subscribe();
    this.filterService.statuses.subscribe( statuses => {
      this.statuses = statuses;
    });
  }

  openMenu(): void {
    const menu = new MDCMenu(this.menu.nativeElement);
    menu.open = true;
  }

  select(): void {
    this.onselect.emit(Object.keys(this.selectedStatuses));
  }

  selectOption(event, option): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.selectedStatuses[option]) {
      delete this.selectedStatuses[option];
    } else {
      this.selectedStatuses[option] = true;
    }

    this.onselect.emit(Object.keys(this.selectedStatuses));

  }

  get selected(): boolean {
    return !!Object.keys(this.selectedStatuses).length;
  }
}
