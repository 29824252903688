<div class="toolbar mdc-menu-surface--anchor">
  <button class="mdc-menu__pseudo-field" (click)="openMenu()">
    <span>
      {{filterService.activeDateFilterOptions.name}}
    </span>
    <i>
      <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.82593 10L13.2607 15L18.6955 10H7.82593Z" fill="black" fill-opacity="0.56"/>
      </svg>
    </i>
  </button>

  <div class="mdc-menu mdc-menu-surface" #menu>
    <ul class="mdc-list" role="menu" aria-hidden="true" aria-orientation="vertical" tabindex="-1">
      <li class="mdc-list-item"
          [ngClass]="{'mdc-list-item--selected': option.name === filterService.activeDateFilterOptions.name}"
          role="menuitem"
          (click)="select(option)"
          *ngFor="let option of filterService.dateFilterOptions; index as i">
        <span class="mdc-list-item__text">
          {{option.name}}
        </span>
      </li>
      <li
        class="mdc-list-item"
        [ngClass]="{'mdc-list-item--selected': dateRangeLabel === filterService.activeDateFilterOptions.name}"
        role="menuitem"
        [owlDateTimeTrigger]="dt1"
      >
        <span
          class="mdc-list-item__text"
        >Date Range</span>
      </li>
    </ul>

    <input
      [owlDateTime]="dt1"
      [selectMode]="'range'"
      (dateTimeChange)="onSelectRange($event.value)"
      class="od-date-range-input"
    />
    <owl-date-time
      #dt1
      [pickerType]="'calendar'"
      [pickerMode]="'dialog'"
    ></owl-date-time>
  </div>

</div>
