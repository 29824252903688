<div class="card">
  <div class="card-body">
    <div class="toggle_row">
      <mat-button-toggle-group #group="matButtonToggleGroup" value="terms" (change)="onValChange(group.value)">
        <mat-button-toggle value="terms">Terms and Conditions</mat-button-toggle>
        <mat-button-toggle value="privacy_policy">Privacy Policy</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="edit_container">
      <quill-editor name="editor" [modules]="options" [(ngModel)]="editorContent"></quill-editor>
    </div>
    <div class="submit_row">
      <button mat-raised-button [routerLink]="['/prays']" color="warn">Cancel</button>
      <button mat-raised-button (click)="undoChanges()">Undo changes</button>
      <button mat-raised-button (click)="submit()" color="accent">Submit changes</button>
    </div>
  </div>
</div>
