import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private _data: {
    statuses: string[]
  };
  private _statuses: BehaviorSubject<string[]>;

  public readonly dateFilterOptions = [
    {
      name: 'All time',
      dateFrom: moment(0).format(),
      dateTo: moment().utc().endOf('day').local().format()
    },
    {
      name: 'Last month',
      dateFrom: moment().utc().subtract(1, 'month').local().format(),
      dateTo: moment().utc().endOf('day').local().format()
    },
    {
      name: 'Last week',
      dateFrom: moment().utc().subtract(1, 'week').local().format(),
      dateTo: moment().utc().endOf('day').local().format()
    }
  ];
  public activeDateFilterOptions;
  public activeStatusFilters: string[];
  constructor(private readonly http: HttpClient) {
    this._data = {
      statuses: []
    };
    this._statuses = new BehaviorSubject([]);

    this.activeDateFilterOptions = this.dateFilterOptions[0];
    this.activeStatusFilters = [];
  }

  public reset(): void {
    this.activeDateFilterOptions = null;
  }

  public changeDateFilterOption(option): void {
    this.activeDateFilterOptions = option;
  }

  public changeStatusFilterOption(options: string[]): void {
    this.activeStatusFilters = options;
  }

  public get statuses(): Observable<string[]> {
    return this._statuses.asObservable();
  }

  public getStatuses(): Observable<string[]> {
    return this.http.get(`${environment.API}/prayer/comment/statuses/`).pipe(
      map((response: string[]) => {
        this._data.statuses = response;
        this._statuses.next([...response]);
        return response;
      })
    );
  }
}
