import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FilterService = /** @class */ (function () {
    function FilterService(http) {
        this.http = http;
        this.dateFilterOptions = [
            {
                name: 'All time',
                dateFrom: moment(0).format(),
                dateTo: moment().utc().endOf('day').local().format()
            },
            {
                name: 'Last month',
                dateFrom: moment().utc().subtract(1, 'month').local().format(),
                dateTo: moment().utc().endOf('day').local().format()
            },
            {
                name: 'Last week',
                dateFrom: moment().utc().subtract(1, 'week').local().format(),
                dateTo: moment().utc().endOf('day').local().format()
            }
        ];
        this._data = {
            statuses: []
        };
        this._statuses = new BehaviorSubject([]);
        this.activeDateFilterOptions = this.dateFilterOptions[0];
        this.activeStatusFilters = [];
    }
    FilterService.prototype.reset = function () {
        this.activeDateFilterOptions = null;
    };
    FilterService.prototype.changeDateFilterOption = function (option) {
        this.activeDateFilterOptions = option;
    };
    FilterService.prototype.changeStatusFilterOption = function (options) {
        this.activeStatusFilters = options;
    };
    Object.defineProperty(FilterService.prototype, "statuses", {
        get: function () {
            return this._statuses.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    FilterService.prototype.getStatuses = function () {
        var _this = this;
        return this.http.get(environment.API + "/prayer/comment/statuses/").pipe(map(function (response) {
            _this._data.statuses = response;
            _this._statuses.next(response.slice());
            return response;
        }));
    };
    FilterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FilterService_Factory() { return new FilterService(i0.ɵɵinject(i1.HttpClient)); }, token: FilterService, providedIn: "root" });
    return FilterService;
}());
export { FilterService };
