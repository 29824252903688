var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FilterService } from './filter.service';
import * as queryString from 'querystring';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { clone, findIndex } from 'lodash';
import { BaseListService } from '../base-list-component/base-list.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./filter.service";
var FlaggedExpressionsService = /** @class */ (function (_super) {
    __extends(FlaggedExpressionsService, _super);
    function FlaggedExpressionsService(http, filterService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.filterService = filterService;
        return _this;
    }
    /**
     * GET list of prayers
     * @param search search query
     */
    FlaggedExpressionsService.prototype.get = function (search) {
        var _this = this;
        if (search === void 0) { search = ''; }
        var query = queryString.stringify({
            limit: this.limit,
            offset: this.offset,
            dateFrom: this.filterService.activeDateFilterOptions.dateFrom,
            dateTo: this.filterService.activeDateFilterOptions.dateTo,
            search: search
        });
        return this.http.get(environment.API + "/abusive-word/?" + query)
            .pipe(map(function (response) {
            _this._data.total = response.total;
            _this._data.list = _this._data.list.concat(response.data);
            _this._list.next(clone(_this._data.list));
            return clone(_this._data.list);
        }));
    };
    FlaggedExpressionsService.prototype.search = function (search) {
        var _this = this;
        var query = queryString.stringify({
            limit: this.limit + this.offset,
            offset: 0,
            dateFrom: this.filterService.activeDateFilterOptions.dateFrom,
            dateTo: this.filterService.activeDateFilterOptions.dateTo,
            search: search
        });
        return this.http.get(environment.API + "/abusive-word/?" + query)
            .pipe(map(function (response) {
            _this._data.total = response.total;
            _this._data.list = response.data;
            _this._list.next(clone(_this._data.list));
            return clone(_this._data.list);
        }));
    };
    /**
     * Remove prayer with 'id'
     * @param id id of prayer
     */
    FlaggedExpressionsService.prototype.remove = function (id) {
        var _this = this;
        return this.http.delete(environment.API + "/abusive-word/" + id + "/").pipe(map(function (response) {
            var index = findIndex(_this._data.list, function (prayer) { return prayer.id === id; });
            _this._data.total--;
            _this._data.list.splice(index, 1);
            _this._list.next(clone(_this._data.list));
            return clone(_this._data.list);
        }));
    };
    /**
     * Create new flagged expression
     */
    FlaggedExpressionsService.prototype.create = function (payload) {
        return this.http.post(environment.API + "/abusive-word/", payload);
    };
    FlaggedExpressionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FlaggedExpressionsService_Factory() { return new FlaggedExpressionsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FilterService)); }, token: FlaggedExpressionsService, providedIn: "root" });
    return FlaggedExpressionsService;
}(BaseListService));
export { FlaggedExpressionsService };
