import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { MDCMenu } from '@material/menu/component';
var BaseListComponent = /** @class */ (function () {
    function BaseListComponent(_baseListService, _headerService, _filterService) {
        this._baseListService = _baseListService;
        this._headerService = _headerService;
        this._filterService = _filterService;
    }
    BaseListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subs = new Subscription();
        this._baseListService.get().subscribe();
        var main = document.querySelector('main');
        // todo: make separate pagination for safari
        this.subs.add(fromEvent(main, 'scroll', { passive: true }).pipe(filter(function () { return _this.list.length < _this._baseListService.total; }), debounceTime(200), distinctUntilChanged()).subscribe(function () {
            // if scrollBottom is less than 300px
            if (_this.scrollBottom < 300) {
                _this._baseListService.addPage();
                _this._baseListService.get(_this._headerService.searchValue).subscribe(function () {
                });
            }
        }));
        this.subs.add(this._baseListService.list.subscribe(function (list) {
            _this.list = list;
        }));
    };
    Object.defineProperty(BaseListComponent.prototype, "scrollBottom", {
        get: function () {
            var main = document.querySelector('main');
            var card = document.querySelector('.card');
            return card.clientHeight - (main.scrollTop + main.clientHeight);
        },
        enumerable: true,
        configurable: true
    });
    BaseListComponent.prototype.ngOnDestroy = function () {
        this._baseListService.reset();
        this._headerService.resetSearch();
        this.subs.unsubscribe();
    };
    BaseListComponent.prototype.dateFilter = function (event) {
        this._filterService.changeDateFilterOption(event);
        this._baseListService.search(this._headerService.searchValue).subscribe();
    };
    BaseListComponent.prototype.openMenu = function (event, index) {
        event.stopPropagation();
        event.preventDefault();
        if (this.openedMenu) {
            this.openedMenu.destroy();
        }
        this.openedMenu = new MDCMenu(document.querySelector("#menu-surface-" + index));
        this.openedMenu.open = true;
    };
    BaseListComponent.prototype.remove = function (event, item) {
        event.stopPropagation();
        event.preventDefault();
        this._baseListService.remove(item.id).subscribe();
    };
    return BaseListComponent;
}());
export { BaseListComponent };
