import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  constructor(public dialog: MatDialog) { }

  canDiactivateComponentDialog(): MatDialogRef<ConfirmDialogComponent, any> {
    return this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {
        title: 'You are trying to leave without saving changes. Are you sure?',
        btnConfirmName: 'Leave page',
      }
    });
  }

  canDeletePrayerDialog(): MatDialogRef<ConfirmDialogComponent, any> {
    return this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {
        title: 'You are about to delete a prayer request. Are you sure?',
      }
    });
  }
}
