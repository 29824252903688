/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./status.component";
import * as i4 from "../../services/filter.service";
var styles_StatusComponent = [i0.styles];
var RenderType_StatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatusComponent, data: {} });
export { RenderType_StatusComponent as RenderType_StatusComponent };
function View_StatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "li", [["class", "mdc-list-item"], ["role", "menuitem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectOption($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "mdc-list-item-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "mdc-form-field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "mdc-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "input", [["class", "mdc-checkbox__native-control"], ["type", "checkbox"]], [[8, "checked", 0], [8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "mdc-checkbox__background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:svg", [["class", "mdc-checkbox__checkmark"], ["viewBox", "0 0 24 24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:path", [["class", "mdc-checkbox__checkmark-path"], ["d", "M1.73,12.91 8.1,19.28 22.79,4.59"], ["fill", "none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "mdc-checkbox__mixedmark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedStatuses[_v.context.$implicit] && "checked"); var currVal_1 = ("checkbox-" + _v.context.index); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = ("checkbox-" + _v.context.index); _ck(_v, 9, 0, currVal_2); var currVal_3 = _v.context.$implicit; _ck(_v, 10, 0, currVal_3); }); }
export function View_StatusComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { menu: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "toolbar mdc-menu-surface--anchor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "button", [["class", "mdc-menu-anchor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "mdc-menu-anchor--active": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 2, "i", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, ":svg:svg", [["fill", "none"], ["height", "24"], ["viewBox", "0 0 27 24"], ["width", "27"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, ":svg:path", [["d", "M10.9086 18H15.2564V16H10.9086V18ZM3.29993 6V8H22.8651V6H3.29993ZM6.5608 13H19.6043V11H6.5608V13Z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Filter "])), (_l()(), i1.ɵeld(11, 0, [[1, 0], ["menu", 1]], null, 3, "div", [["class", "mdc-menu mdc-menu-surface"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "ul", [["aria-hidden", "true"], ["aria-orientation", "vertical"], ["class", "mdc-list"], ["role", "menu"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusComponent_1)), i1.ɵdid(14, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "mdc-menu-anchor"; var currVal_1 = _ck(_v, 5, 0, _co.selected); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.statuses; _ck(_v, 14, 0, currVal_2); }, null); }
export function View_StatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-status", [], null, null, null, View_StatusComponent_0, RenderType_StatusComponent)), i1.ɵdid(1, 114688, null, 0, i3.StatusComponent, [i4.FilterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StatusComponentNgFactory = i1.ɵccf("app-status", i3.StatusComponent, View_StatusComponent_Host_0, {}, { onselect: "onselect" }, []);
export { StatusComponentNgFactory as StatusComponentNgFactory };
