import { Component, OnInit } from '@angular/core';
import { FlaggedExpressionsService } from '../services/flagged-expressions.service';
import { HeaderService } from '../services/header.service';
import { BaseListComponent } from '../base-list-component/base-list.component';
import { FilterService } from '../services/filter.service';

@Component({
  selector: 'app-flagged-expressions',
  templateUrl: './flagged-expressions.component.html',
  styleUrls: ['./flagged-expressions.component.scss']
})
export class FlaggedExpressionsComponent extends BaseListComponent implements OnInit {
  constructor(private readonly flaggedService: FlaggedExpressionsService,
              private readonly header: HeaderService,
              private readonly filter: FilterService) {
    super(flaggedService, header, filter);
  }
}
