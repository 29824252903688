<form #formRef="ngForm" (ngSubmit)="onSubmit(formRef)">
  <div class="login_container">
    <img src="../assets/images/logo.svg" alt="OpenDoors">
    <mat-form-field>
      <input
        name="login"
        placeholder="Login"
        matInput
        required
        ngModel
        type="text">
        <mat-error *ngIf="formRef.controls.login?.errors?.required">
          This field is required!
        </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        placeholder="Password"
        matInput
        required
        ngModel
        name="password"
        type="password">
        <mat-error *ngIf="formRef.controls.password?.errors?.required">
          This field is required!
        </mat-error>
    </mat-form-field>
    <button
      color="accent"
      type="Submit"
      mat-raised-button
      [disabled]="!formRef.valid"
    >Sign in</button>
    <mat-checkbox
      ngModel
      name="remember"
    >Remember me!</mat-checkbox>
  </div>
</form>
<div *ngIf="submitMessage" class="notify_container">
  {{ submitMessage }}
</div>