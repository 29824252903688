/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header/header.component.ngfactory";
import * as i3 from "./header/header.component";
import * as i4 from "./services/header.service";
import * as i5 from "./sidenav-controls/sidenav-controls.component.ngfactory";
import * as i6 from "./sidenav-controls/sidenav-controls.component";
import * as i7 from "./data.service";
import * as i8 from "@angular/router";
import * as i9 from "./app.component";
import * as i10 from "@angular/material/snack-bar";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { sidenav: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(3, 4308992, null, 0, i3.HeaderComponent, [i4.HeaderService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "aside", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-sidenav-controls", [], null, null, null, i5.View_SidenavControlsComponent_0, i5.RenderType_SidenavControlsComponent)), i1.ɵdid(6, 4308992, null, 0, i6.SidenavControlsComponent, [i7.DataService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 6, 0); _ck(_v, 9, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i9.AppComponent, [i7.DataService, i10.MatSnackBar, i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
