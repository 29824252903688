import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { Comment } from '../models/comment';
import { FilterService } from '../services/filter.service';
import { CommentsService } from '../services/comments.service';
import { HeaderService } from '../services/header.service';

import { BaseListComponent } from '../base-list-component/base-list.component';
import { Prayer } from '../models/prayer';


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent extends BaseListComponent implements OnInit, OnDestroy {
  public list = this.list as Comment[];
  public commentId: number;
  public prayerId: Prayer['id'];

  constructor(private readonly filterService: FilterService,
              public readonly commentsService: CommentsService,
              private readonly headerService: HeaderService,
              private readonly route: ActivatedRoute,
              private readonly router: Router) {
    super(commentsService, headerService, filterService);
  }

  ngOnInit(): void {
    this.prayerId = this.route.snapshot.params.id;

    if (this.prayerId) {
      this.subs = new Subscription();
      this.commentsService.getByPrayerId(this.prayerId).subscribe();

      this.subs.add(
        this.commentsService.list.subscribe(list => {
          this.list = list;
        })
      );

      this.subs.add(
        this.route.queryParams.subscribe(query => {
          this.commentId = parseInt(query['comment-id'], 10);
        })
      );
    } else {
      super.ngOnInit();
    }
  }

  statusFilter(event): void {
    this.filterService.changeStatusFilterOption(event);
    this.commentsService.search(this.headerService.searchValue).subscribe();
  }

  post(event, comment: Comment): void {
    event.stopPropagation();
    event.preventDefault();

    this.commentsService.postComment(comment.id).subscribe();
  }

  redirect(prayerId: number, commentId): void {
    if (this.commentId) {
      return;
    }
    this.router.navigate(['/prayers', 'edit', prayerId], {
      queryParams: {
        'comment-id': commentId
      }
    });
  }
}
