import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
var BaseListService = /** @class */ (function () {
    function BaseListService() {
        this.offset = 0;
        this.limit = 20;
        this._data = {
            list: [],
            total: 0
        };
        this._list = new BehaviorSubject([]);
    }
    Object.defineProperty(BaseListService.prototype, "total", {
        get: function () {
            return this._data.total;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaseListService.prototype, "list", {
        get: function () {
            return this._list.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Adds offset equal to limit
     */
    BaseListService.prototype.addPage = function () {
        this.offset += this.limit;
    };
    BaseListService.prototype.reset = function () {
        this.offset = 0;
        this._data.list = [];
    };
    BaseListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseListService_Factory() { return new BaseListService(); }, token: BaseListService, providedIn: "root" });
    return BaseListService;
}());
export { BaseListService };
