var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MDCTabBar } from '@material/tab-bar';
import { MDCTextField } from '@material/textfield';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isEqual } from 'lodash';
import { map, take } from 'rxjs/operators';
import * as moment from 'moment';
import { DataService } from '../data.service';
import { HeaderService } from '../services/header.service';
import { ConfirmationDialogService } from '../services';
var TABS;
(function (TABS) {
    TABS["INFO"] = "INFO";
    TABS["COMMENTS"] = "COMMENTS";
})(TABS || (TABS = {}));
var CreatePrayComponent = /** @class */ (function () {
    function CreatePrayComponent(dataService, router, route, headerService, confirmationDialogService) {
        this.dataService = dataService;
        this.router = router;
        this.route = route;
        this.headerService = headerService;
        this.confirmationDialogService = confirmationDialogService;
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.editorContent = '';
        this.title = '';
        this.cropperIsOpen = false;
        this.btndisabled = false;
        this.TABS = TABS;
        this.b64toBlob = function (b64Data, contentType, sliceSize) {
            if (contentType === void 0) { contentType = ''; }
            if (sliceSize === void 0) { sliceSize = 512; }
            var byteCharacters = atob(b64Data);
            var byteArrays = [];
            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);
                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            var blob = new Blob(byteArrays, { type: contentType });
            return blob;
        };
    }
    CreatePrayComponent.prototype.onbeforeunload = function () {
        return !this.isChangesExists();
    };
    CreatePrayComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subs = new Subscription();
        this.dataService.changeSideNav(true);
        this.id = this.route.snapshot.params.id;
        this.currentMoment = new Date();
        if (this.id) {
            this.dataService.getPray(this.id).subscribe(function (result) {
                _this.title = result.title;
                _this.editorContent = result.story;
                if (result.schedule_date) {
                    _this.selectedDate = new Date(result.schedule_date);
                }
                else {
                    _this.selectedDate = null;
                }
                _this.croppedImage = result.image;
                _this.form.patchValue(result);
                _this.formDataSnapshot = __assign({}, _this.form.value);
            }, function () {
                _this.router.navigateByUrl('/prayers');
            });
        }
        else {
            this.create = true;
            this.title = '';
            this.editorContent = '';
            this.croppedImage = '';
        }
        this.subs.add(this.headerService.saveObservable.subscribe(function () {
            _this.onSubmit(_this.form);
        }));
        this.subs.add(this.route.queryParams.subscribe(function (query) {
            if (query['comment-id']) {
                _this.changeTab(TABS.COMMENTS, 1);
            }
            else {
                _this.changeTab(TABS.INFO, 0);
            }
        }));
        this.formInit();
        this.formDataSnapshot = __assign({}, this.form.value);
    };
    CreatePrayComponent.prototype.ngOnDestroy = function () {
        this.subs.unsubscribe();
    };
    CreatePrayComponent.prototype.formInit = function () {
        this.form = new FormGroup({
            title: new FormControl('', [Validators.required]),
            country: new FormControl(null),
            story: new FormControl(''),
            image: new FormControl('', [Validators.required]),
            schedule_date: new FormControl(''),
            preview_story: new FormControl('', [Validators.required]),
            video_link: new FormControl(''),
            is_urgent: new FormControl(false),
        });
    };
    CreatePrayComponent.prototype.ngAfterViewInit = function () {
        var inputs = document.querySelectorAll('.mdc-text-field');
        inputs.forEach(function (input) {
            return new MDCTextField(input);
        });
    };
    CreatePrayComponent.prototype.triggerClick = function (elem) {
        elem.click();
    };
    CreatePrayComponent.prototype.cropImage = function (elem) {
        this.imageChangedEvent = null;
        this.cropperIsOpen = false;
        elem.value = '';
    };
    CreatePrayComponent.prototype.fileChangeEvent = function (event) {
        if (!event.target.files.length) {
            return;
        }
        // 32MB
        if (event.target.files[0].size > 32000000) {
            return this.dataService.showNotification('Picture is too big, try an image with size less than 32MB', null, 4000, 'success');
        }
        this.imageChangedEvent = event;
    };
    CreatePrayComponent.prototype.imageCropped = function (image) {
        this.croppedImage = image.base64;
    };
    CreatePrayComponent.prototype.imageLoaded = function () {
        this.cropperIsOpen = true;
    };
    CreatePrayComponent.prototype.setCurrentMoment = function () {
        this.currentMoment = new Date();
    };
    CreatePrayComponent.prototype.onSubmit = function (form) {
        var _this = this;
        this.form.markAllAsTouched();
        this.form.patchValue({
            image: this.croppedImage
        });
        var datePickerMinDateError = this.form.get('schedule_date').errors && this.form.get('schedule_date').errors.owlDateTimeMin;
        var isTimeTravel = datePickerMinDateError && this.id;
        if (this.form.invalid && !isTimeTravel) {
            return;
        }
        form = this.form;
        var convertedData = new FormData();
        if (this.form.value.schedule_date) {
            var scheduleDate = moment(form.value.schedule_date);
            convertedData.append('schedule_date', scheduleDate.toISOString());
        }
        else {
            convertedData.append('schedule_date', null);
        }
        convertedData.append('video_link', form.value.video_link || null);
        convertedData.append('story', form.value.story);
        convertedData.append('title', form.value.title);
        convertedData.append('preview_story', form.value.preview_story);
        convertedData.append('country', form.value.country);
        convertedData.append('is_urgent', form.value.is_urgent);
        this.btndisabled = true;
        var base64Rejex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
        var isBase64Valid = base64Rejex.test(form.value.image);
        if (isBase64Valid) {
            var convertedImage = form.value.image;
            var dataArray = convertedImage.match(/^data:(.*);base64,(.*)$/);
            convertedImage = this.b64toBlob(dataArray[2], dataArray[1]);
            convertedData.append('image', convertedImage);
        }
        this.headerService.blockSaving();
        if (this.create) {
            this.dataService.addPrayRequest(convertedData).subscribe(function () {
                _this.formDataSnapshot = __assign({}, _this.form.value);
                _this.router.navigateByUrl('/prayers').then(function () {
                    _this.headerService.unblockSaving();
                });
                _this.dataService.showNotification('Prayer request has been added successfully!', null, 4000, 'success');
            }, function () {
                _this.headerService.unblockSaving();
            });
        }
        else {
            this.dataService.updatePrayRequest(convertedData, this.id).subscribe(function () {
                _this.formDataSnapshot = __assign({}, _this.form.value);
                _this.router.navigateByUrl('/prayers').then(function () {
                    _this.headerService.unblockSaving();
                });
                _this.dataService.showNotification('Prayer request has been changed successfully!', null, 4000, 'success');
            }, function () {
                _this.headerService.unblockSaving();
            });
        }
    };
    CreatePrayComponent.prototype.changeTab = function (tab, index) {
        this.activeTab = tab;
        setTimeout(function () {
            new MDCTabBar(document.querySelector(".mdc-tab-bar")).activateTab(index);
        }, 0);
    };
    CreatePrayComponent.prototype.canDeactivate = function () {
        if (this.isChangesExists()) {
            return this.confirmationDialogService.canDiactivateComponentDialog()
                .afterClosed()
                .pipe(map(function (response) {
                return response ? response.isAccept : false;
            }), take(1));
        }
        return true;
    };
    CreatePrayComponent.prototype.isChangesExists = function () {
        return !isEqual(this.formDataSnapshot, this.form.value);
    };
    return CreatePrayComponent;
}());
export { CreatePrayComponent };
