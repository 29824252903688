import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from './data.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "./data.service";
var AuthorizationService = /** @class */ (function () {
    function AuthorizationService(router, http, dataService) {
        this.router = router;
        this.http = http;
        this.dataService = dataService;
        this.refresh = true;
        this.API = environment.API;
    }
    AuthorizationService.prototype.login = function (datas) {
        var _this = this;
        var credentials = {
            password: datas.password,
            username: datas.login
        };
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.http.post(this.API + '/token/', JSON.stringify(credentials), { headers: headers }).subscribe(function (result) {
            if (datas.remember) {
                try {
                    localStorage.setItem('refreshToken', result.refresh);
                }
                catch (err) {
                    console.log(err);
                    _this.dataService.showNotification('Critical error: ' + err, null, 4000, 'error');
                }
            }
            else {
                try {
                    localStorage.removeItem('refreshToken');
                }
                catch (err) {
                    console.log(err);
                    _this.dataService.showNotification('Critical error: ' + err, null, 4000, 'error');
                }
            }
            try {
                sessionStorage.setItem('refreshToken', result.refresh);
                sessionStorage.setItem('accessToken', result.access);
            }
            catch (err) {
                console.log(err);
                _this.dataService.showNotification('Critical error: ' + err, null, 4000, 'error');
            }
            _this.dataService.showNotification('You have been successfully logged in.', null, 4000, 'success');
            _this.router.navigateByUrl('/prayers');
        }, function (err) {
        });
    };
    AuthorizationService.prototype.refreshToken = function () {
        var data = {
            refresh: sessionStorage.getItem('refreshToken')
        };
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(this.API + '/refresh-token/', JSON.stringify(data), { headers: headers });
    };
    AuthorizationService.prototype.isAuthenticated = function () {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            var refreshToken = sessionStorage.getItem('refreshToken');
            if (!refreshToken) {
                try {
                    refreshToken = localStorage.getItem('refreshToken');
                    sessionStorage.setItem('refreshToken', refreshToken);
                }
                catch (err) {
                    _this.dataService.showNotification('Critical error: ' + err, null, 4000, 'error');
                }
            }
            if (!refreshToken) {
                _this.dataService.logOut('You have to be logged in to have access to this page. Please input your credentials.');
                resolve(false);
            }
            else {
                var data = {
                    refresh: refreshToken
                };
                var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
                _this.http.post(_this.API + '/refresh-token/', JSON.stringify(data), { headers: headers }).subscribe(function (result) {
                    try {
                        var newAccessToken = result.access;
                        var newRefreshToken = result.refresh;
                        sessionStorage.setItem('refreshToken', newRefreshToken);
                        sessionStorage.setItem('accessToken', newAccessToken);
                        localStorage.getItem('refreshToken') ? localStorage.setItem('refreshToken', newRefreshToken) : null;
                        resolve(true);
                    }
                    catch (err) {
                        _this.dataService.showNotification('Critical error: ' + err, null, 4000, 'error');
                        _this.dataService.logOut('You have to be logged in to have access to this page. Please input your credentials.');
                        resolve(false);
                    }
                }, function (err) {
                    resolve(false);
                });
            }
        });
        return promise;
    };
    AuthorizationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizationService_Factory() { return new AuthorizationService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.DataService)); }, token: AuthorizationService, providedIn: "root" });
    return AuthorizationService;
}());
export { AuthorizationService };
