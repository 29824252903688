import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../data.service';
import { AuthorizationService } from '../authorization.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent implements OnInit {

  submitMessage: string;
  clearingTimeout;
  
  constructor(private router: Router, private dataService: DataService, private authService: AuthorizationService) {
  }

  ngOnInit() {
    this.dataService.changeSideNav(false);
  };
  
  onSubmit(form) {
    this.authService.login(form.value);
  }
  
}
