import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

export interface LinkInterface {
  [name: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class LinksService {

  constructor(private readonly httpClient: HttpClient) { }

  getLinks(): Observable<LinkInterface> {
    return this.httpClient.get<LinkInterface>(`${environment.API}/links/`);
  }

  updateLinks(links: LinkInterface): Observable<LinkInterface> {
    return this.httpClient.put<LinkInterface>(`${environment.API}/links/`, links);
  }
}
