var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, take, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isEqual } from 'lodash';
import { HeaderService } from '../services/header.service';
import { LinksService } from '../services/links.service';
import { DataService } from '../data.service';
import { ConfirmationDialogService } from '../services';
var urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/.*';
var UpdateLinksComponent = /** @class */ (function () {
    function UpdateLinksComponent(headerService, dataService, activatedRoute, cdr, confirmationDialogService, linksService) {
        this.headerService = headerService;
        this.dataService = dataService;
        this.activatedRoute = activatedRoute;
        this.cdr = cdr;
        this.confirmationDialogService = confirmationDialogService;
        this.linksService = linksService;
        this.destroy$ = new Subject();
    }
    UpdateLinksComponent.prototype.onbeforeunload = function () {
        return !this.isChangesExists();
    };
    UpdateLinksComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.links = __assign({}, this.activatedRoute.snapshot.data.links);
        this.form = new FormGroup(__assign({}, this.getFormControlsLabel(Object.keys(this.links)), this.getFormControlsValue(this.links)));
        this.headerService.unblockSaving();
        this.headerService
            .saveObservable
            .pipe(takeUntil(this.destroy$))
            .subscribe(function () {
            _this.onSubmit(_this.form);
            _this.cdr.detectChanges();
        });
    };
    UpdateLinksComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.complete();
    };
    UpdateLinksComponent.prototype.getFormControlName = function (name, preffix) {
        return "" + name.trim().toLocaleLowerCase().split(' ').join('_') + (preffix ? '_' + preffix : '');
    };
    UpdateLinksComponent.prototype.getFormControls = function () {
        return Object.keys(this.links);
    };
    UpdateLinksComponent.prototype.canDeactivate = function () {
        if (this.isChangesExists()) {
            return this.confirmationDialogService.canDiactivateComponentDialog()
                .afterClosed()
                .pipe(map(function (response) {
                return response ? response.isAccept : false;
            }), take(1));
        }
        return true;
    };
    UpdateLinksComponent.prototype.isChangesExists = function () {
        return !isEqual(this.links, this.getDataForUpdate(this.links, this.form.value));
    };
    UpdateLinksComponent.prototype.onSubmit = function (form) {
        var _this = this;
        form.markAllAsTouched();
        if (form.valid) {
            this.headerService.blockSaving();
            this.linksService
                .updateLinks(this.getDataForUpdate(this.links, form.value))
                .pipe(take(1))
                .subscribe(function () {
                _this.links = __assign({}, _this.getDataForUpdate(_this.links, form.value));
                _this.dataService.showNotification('Links has been updated successfully!', null, 4000, 'success');
                _this.headerService.unblockSaving();
            }, function () {
                _this.headerService.unblockSaving();
            });
        }
    };
    UpdateLinksComponent.prototype.getFormControlsLabel = function (keys) {
        var _this = this;
        return keys.reduce(function (acc, current) {
            var _a;
            return __assign({}, acc, (_a = {}, _a[_this.getFormControlName(current)] = new FormControl(current), _a));
        }, {});
    };
    UpdateLinksComponent.prototype.getFormControlsValue = function (data) {
        var _this = this;
        return Object.keys(data).reduce(function (acc, current) {
            var _a;
            return __assign({}, acc, (_a = {},
                _a[_this.getFormControlName(current, 'value')] = new FormControl(data[current], [Validators.required, Validators.pattern(urlPattern)]),
                _a));
        }, {});
    };
    UpdateLinksComponent.prototype.getDataForUpdate = function (links, data) {
        var _this = this;
        return Object.keys(links).reduce(function (acc, item) {
            var _a;
            var propertyValue = data[_this.getFormControlName(item, 'value')];
            return __assign({}, acc, (_a = {}, _a[item] = propertyValue, _a));
        }, {});
    };
    return UpdateLinksComponent;
}());
export { UpdateLinksComponent };
