import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import * as moment from 'moment';

import { PrayersService } from '../services/prayers.service';
import { HeaderService } from '../services/header.service';
import { Prayer } from '../models/prayer';
import { FilterService } from '../services/filter.service';
import { BaseListComponent } from '../base-list-component/base-list.component';
import { ConfirmationDialogService } from '../services';

@Component({
  selector: 'app-prays',
  templateUrl: './prays.component.html',
  styleUrls: ['./prays.component.scss']
})
export class PraysComponent extends BaseListComponent {
  // a hack, so list has a specific model behind it
  list = this.list as Prayer[];
  private readonly displayPrayerPreview: Map<number, boolean> = new Map<number, boolean>();

  constructor(
    private readonly prayersService: PrayersService,
    private readonly headerService: HeaderService,
    private readonly router: Router,
    private readonly confirmationDialogService: ConfirmationDialogService,
    private readonly filterService: FilterService,
  ) {
    super(prayersService, headerService, filterService);
  }

  /**
   * Change 'featured' property of the prayer
   * @param event
   * @param prayer
   */
  toggleFeatured(event: Event, prayer: Prayer): void {
    event.stopPropagation();
    event.preventDefault();

    this.prayersService.toggleFeatured(prayer.id).subscribe(response => {
      this.prayersService.search(this.headerService.searchValue)
        .subscribe(prayers => {
          this.displayPrayerPreview.set(prayer.id, false);
          this.list = prayers;
        });
    });
  }

  /**
   * Toggle 'is_comment_enable' of prayer true or false
   * @param event - Event
   * @param prayer - prayer [Prayer]
   */
  togglePrayerCommentStatus(event: Event, prayer: Prayer): void {
    event.stopPropagation();
    event.preventDefault();

    this.prayersService.togglePrayerCommentStatus(prayer.id, !prayer.is_comment_enable)
      .subscribe(() => {
        this.prayersService.search(this.headerService.searchValue)
          .subscribe(prayers => {
            this.displayPrayerPreview.set(prayer.id, false);
            this.list = prayers;
          });
      });
  }

  /**
   * Checks if prayer is posted and shows date if true
   * @param prayer
   */
  public postedDate(prayer: Prayer): string {
    const scheduleDateUnix = Date.parse(prayer.schedule_date);
    const creationDateUnix = Date.parse(prayer.creation_date);
    if (prayer.schedule_date) {
      if (scheduleDateUnix < creationDateUnix) {
        return '-';
      } else {
        return moment(scheduleDateUnix).format('MM.DD.YYYY hh:mm A');
      }
    }

    return moment(creationDateUnix).format('MM.DD.YYYY hh:mm A');
  }

  /**
   * Toggle preview mode
   * @param prayer
   */
  togglePreviewMode(prayer: Prayer): void {
    this.displayPrayerPreview.set(prayer.id, !this.isDisplayPreview(prayer));
  }

  /**
   * Check is display preview mode for prayer
   * @param prayer
   */
  isDisplayPreview(prayer): boolean {
    return this.displayPrayerPreview.get(prayer.id);
  }

  onEditPrayer(event: Event, prayer: Prayer): void {
    event.stopPropagation();
    event.preventDefault();

    this.router.navigate([ '/prayers', 'edit', prayer.id ]);
  }

  remove(event, item): void {
    event.stopPropagation();
    event.preventDefault();

    this.confirmationDialogService.canDeletePrayerDialog()
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: { isAccept: boolean }) => {
        if (result && result.isAccept) {
          super.remove(event, item);
        }
      })
  }

}
