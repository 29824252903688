import {Component, Input, OnInit} from '@angular/core';
import Quill from 'quill';
import {FormControl} from "@angular/forms";
Quill.register(Quill.import('attributors/style/direction'), true);
Quill.register(Quill.import('attributors/style/align'), true);
const QuillFont = Quill.import('formats/font');
QuillFont.whitelist = ['inconsolata', 'roboto', 'mirza', 'arial', 'comic sans'];
Quill.register(QuillFont, true);
const QuillSize = Quill.import('attributors/style/size');
QuillSize.whitelist = ['10px', '14px', '18px', '24px', '32px'];
Quill.register(QuillSize, true);

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {
  options = {
    toolbar: [
      [
        "bold", "italic", "underline", "strike"
      ],
      [
        "blockquote"
      ],
      [
        {
          "header": 1
        },
        {
          "header": 2
        }
      ],
      [
        {
          "list": "ordered"
        },
        {
          "list": "bullet"
        }
      ],
      [
        {
          "script": "sub"
        },
        {
          "script": "super"
        }
      ],
      [
        {
          "indent": "-1"
        },
        {
          "indent": "+1"
        }
      ],
      [{"direction": "rtl"}],
      [
        {"header": [1, 2, 3, 4, 5, 6, false]}
      ],
      [{"align": []}],
      ["clean"],
      ["link"],
    ]
  };

  @Input() control: FormControl;
  constructor() { }

  ngOnInit() {
  }

}
