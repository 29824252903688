<ul class="list">
  <li class="list-item" routerLinkActive="list-item--active" [routerLink]="['/prayers']">
    <i>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 5.7207V19.7207H5V5.7207H19ZM20.1 3.7207H3.9C3.4 3.7207 3 4.1207 3 4.6207V20.8207C3 21.2207 3.4 21.7207 3.9 21.7207H20.1C20.5 21.7207 21 21.2207 21 20.8207V4.6207C21 4.1207 20.5 3.7207 20.1 3.7207ZM11 7.7207H17V9.7207H11V7.7207ZM11 11.7207H17V13.7207H11V11.7207ZM11 15.7207H17V17.7207H11V15.7207ZM7 7.7207H9V9.7207H7V7.7207ZM7 11.7207H9V13.7207H7V11.7207ZM7 15.7207H9V17.7207H7V15.7207Z"/>
      </svg>
    </i>
    <span>
      Prayers requests
    </span>
  </li>
  <li class="list-item" routerLinkActive="list-item--active" [routerLink]="['/comments']">
    <i>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.99 4.7207C21.99 3.6207 21.1 2.7207 20 2.7207H4C2.9 2.7207 2 3.6207 2 4.7207V16.7207C2 17.8207 2.9 18.7207 4 18.7207H18L22 22.7207L21.99 4.7207ZM18 14.7207H6V12.7207H18V14.7207ZM18 11.7207H6V9.7207H18V11.7207ZM18 8.7207H6V6.7207H18V8.7207Z"/>
      </svg>
    </i>
    <span>
      Prayers
    </span>
  </li>
  <li class="list-item" routerLinkActive="list-item--active" [routerLink]="['/flagged-expressions']">
    <i>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 5.7207C16.67 5.7207 17.27 6.0507 17.63 6.5607L21.5895 12.1421C21.8354 12.4887 21.8354 12.9527 21.5895 13.2993L17.63 18.8807C17.27 19.3907 16.67 19.7207 16 19.7207L5 19.7107C3.9 19.7107 3 18.8207 3 17.7207V7.7207C3 6.6207 3.9 5.7307 5 5.7307L16 5.7207ZM15.3647 7.14044C15.9093 7.14044 16.1501 7.4035 16.4427 7.81006L19.571 12.1346C19.8241 12.4844 19.8241 12.957 19.571 13.3068L16.4427 17.6313C16.1501 18.0379 15.9093 18.3009 15.3647 18.3009L5.87114 18.293C4.97696 18.293 4.55015 17.8427 4.55015 16.9658V8.40887C4.55015 7.53198 4.97696 7.1484 5.87114 7.1484L15.3647 7.14044Z"/>
      </svg>
    </i>
    <span>
      Flagged expressions
    </span>
  </li>
  <li class="list-item" routerLinkActive="list-item--active" [routerLink]="['/terms-and-privacy_policy']">
    <i>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 1.7207L3 5.7207V11.7207C3 17.2707 6.84 22.4607 12 23.7207C17.16 22.4607 21 17.2707 21 11.7207V5.7207L12 1.7207ZM19 7.0207V12.7107C18.47 16.8307 15.72 20.5007 12 21.6507C12 21.6507 9.645 20.7279 8.55965 19.7588C7.4743 18.7898 6.75072 17.9959 6.04009 16.5803C5.33975 15.1852 5 12.7207 5 12.7207V7.0207L12 3.9107L19 7.0207Z"/>
      </svg>
    </i>
    <span>
      Terms and Privacy Policy
    </span>
  </li>
  <li class="list-item" routerLinkActive="list-item--active" [routerLink]="['/links']">
    <i>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.32578 12.7207C4.32578 11.0107 5.71578 9.6207 7.42578 9.6207H11.4258V7.7207H7.42578C4.66578 7.7207 2.42578 9.9607 2.42578 12.7207C2.42578 15.4807 4.66578 17.7207 7.42578 17.7207H11.4258V15.8207H7.42578C5.71578 15.8207 4.32578 14.4307 4.32578 12.7207ZM8.42578 13.7207H16.4258V11.7207H8.42578V13.7207ZM17.4258 7.7207H13.4258V9.6207H17.4258C19.1358 9.6207 20.5258 11.0107 20.5258 12.7207C20.5258 14.4307 19.1358 15.8207 17.4258 15.8207H13.4258V17.7207H17.4258C20.1858 17.7207 22.4258 15.4807 22.4258 12.7207C22.4258 9.9607 20.1858 7.7207 17.4258 7.7207Z" fill="#0075FF"/>
      </svg>        
    </i>
    <span>
      Menu links
    </span>
  </li>
  <li class="list-item" (click)="logOut()">
    <i>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.09 16.3107L11.5 17.7207L16.5 12.7207L11.5 7.7207L10.09 9.1307L12.67 11.7207H3V13.7207H12.67L10.09 16.3107ZM19 3.7207H5C3.89 3.7207 3 4.6207 3 5.7207V9.7207H5V5.7207H19V19.7207H5V15.7207H3V19.7207C3 20.8207 3.89 21.7207 5 21.7207H19C20.1 21.7207 21 20.8207 21 19.7207V5.7207C21 4.6207 20.1 3.7207 19 3.7207Z"/>
      </svg>
    </i>
    <span>
      Log out
    </span>
  </li>
</ul>

<div class="button-container">
  <button class="mdc-button mdc-button--raised" (click)="getEmails()" #userEmails>
    <i class="" aria-hidden="true">
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5653 11.2646C16.7993 11.2646 17.0117 11.4007 17.1084 11.6144C17.2051 11.8281 17.1693 12.0788 17.0141 12.2542L12.8359 17.0294C12.7225 17.1583 12.5589 17.2335 12.387 17.2335C12.2151 17.2335 12.0516 17.1595 11.9382 17.0294L7.75992 12.2542C7.60592 12.0776 7.56772 11.8281 7.66561 11.6144C7.76231 11.4019 7.9748 11.2646 8.20878 11.2646H10.5963V2.505H14.1777V11.2646H16.5653Z" fill="white"/>
        <path d="M18.9528 16.0398V19.6211H5.82116V16.0398H3.43359V20.8149C3.43359 21.4751 3.96841 22.0087 4.62738 22.0087H20.1466C20.8067 22.0087 21.3404 21.4751 21.3404 20.8149V16.0398H18.9528Z" fill="white"/>
      </svg>
    </i>
    <span class="mdc-button__label">Download user emails</span>
  </button>
</div>
