import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var ConfirmationDialogService = /** @class */ (function () {
    function ConfirmationDialogService(dialog) {
        this.dialog = dialog;
    }
    ConfirmationDialogService.prototype.canDiactivateComponentDialog = function () {
        return this.dialog.open(ConfirmDialogComponent, {
            width: '350px',
            data: {
                title: 'You are trying to leave without saving changes. Are you sure?',
                btnConfirmName: 'Leave page',
            }
        });
    };
    ConfirmationDialogService.prototype.canDeletePrayerDialog = function () {
        return this.dialog.open(ConfirmDialogComponent, {
            width: '350px',
            data: {
                title: 'You are about to delete a prayer request. Are you sure?',
            }
        });
    };
    ConfirmationDialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfirmationDialogService_Factory() { return new ConfirmationDialogService(i0.ɵɵinject(i1.MatDialog)); }, token: ConfirmationDialogService, providedIn: "root" });
    return ConfirmationDialogService;
}());
export { ConfirmationDialogService };
