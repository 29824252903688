import { PrayersService } from './prayers.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, skip } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CommentsService } from './comments.service';
import { environment } from '../../environments/environment';
import { DataService } from '../data.service';
import { FlaggedExpressionsService } from './flagged-expressions.service';
import * as i0 from "@angular/core";
import * as i1 from "./prayers.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./comments.service";
import * as i4 from "./flagged-expressions.service";
import * as i5 from "../data.service";
var HeaderService = /** @class */ (function () {
    function HeaderService(prayersService, http, commentsService, expressionsService, dataService) {
        this.prayersService = prayersService;
        this.http = http;
        this.commentsService = commentsService;
        this.expressionsService = expressionsService;
        this.dataService = dataService;
        this._keyword = '';
        this._isSavingPaused = false;
        this._save = new BehaviorSubject(true);
        this._keywordObservable = new BehaviorSubject('');
        this._savePause = new Subject();
    }
    Object.defineProperty(HeaderService.prototype, "searchValue", {
        get: function () {
            return this._keyword;
        },
        set: function (value) {
            this._keyword = value;
            this.prayersService.search(this._keyword).subscribe();
        },
        enumerable: true,
        configurable: true
    });
    HeaderService.prototype.save = function () {
        this._save.next(true);
    };
    Object.defineProperty(HeaderService.prototype, "saveObservable", {
        get: function () {
            var _this = this;
            return this._save.asObservable().pipe(skip(1), filter(function () { return _this.canSave; }));
        },
        enumerable: true,
        configurable: true
    });
    HeaderService.prototype.blockSaving = function () {
        this._isSavingPaused = true;
    };
    HeaderService.prototype.unblockSaving = function () {
        this._isSavingPaused = false;
    };
    Object.defineProperty(HeaderService.prototype, "canSave", {
        get: function () {
            return !this._isSavingPaused;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderService.prototype, "commentSearch", {
        set: function (value) {
            this._keyword = value;
            this.commentsService.search(this._keyword).subscribe();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderService.prototype, "expressionSearch", {
        set: function (value) {
            this._keyword = value;
            this.expressionsService.search(this._keyword).subscribe();
        },
        enumerable: true,
        configurable: true
    });
    HeaderService.prototype.resetSearch = function () {
        this._keyword = '';
    };
    Object.defineProperty(HeaderService.prototype, "keyword", {
        get: function () {
            return this._keywordObservable.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    HeaderService.prototype.exportComments = function () {
        var _this = this;
        this.http.get(environment.API + "/statistic/", { responseType: 'blob' }).subscribe(function (result) {
            var blob = new Blob([result], { type: 'text/csv' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'prayers.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            _this.dataService.showNotification('Prayers exported successfully.', null, 4000, 'success');
        }, function (err) {
            console.log(err);
            _this.dataService.showNotification('Critical error happened. Error status: ' + err.status, null, 6000, 'error');
        });
    };
    HeaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HeaderService_Factory() { return new HeaderService(i0.ɵɵinject(i1.PrayersService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.CommentsService), i0.ɵɵinject(i4.FlaggedExpressionsService), i0.ɵɵinject(i5.DataService)); }, token: HeaderService, providedIn: "root" });
    return HeaderService;
}());
export { HeaderService };
