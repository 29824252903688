<div class="card">
  <div class="card-body">
    <form [formGroup]="form">
      <h3 class="title">
        Flagged expression
      </h3>
      <div class="input">
        <div class="mdc-text-field mdc-text-field--outlined mdc-text-field--no-label"
             [ngClass]="{'mdc-text-field--error': form.get('token').invalid && form.get('token').touched}">
          <input type="text"
                 placeholder="Put the words here"
                 class="mdc-text-field__input"
                 maxlength="100"
                 formControlName="token">
          <div class="mdc-notched-outline">
            <div class="mdc-notched-outline__leading"></div>
            <div class="mdc-notched-outline__trailing"></div>
          </div>
        </div>
        <div class="mdc-text-field-helper-line">
          <div
            class="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg mdc-text-field-helper-text--persistent"
            *ngIf="form.get('token').invalid && form.get('token').touched">
            This field is required!
          </div>
          <div class="mdc-text-field-character-counter">{{form.get('token').value.length}} / 150</div>
        </div>
      </div>
    </form>
  </div>
</div>
