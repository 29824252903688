import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, HostListener, ContentChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { MDCTabBar } from '@material/tab-bar';
import { MDCTextField } from '@material/textfield';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isEqual } from 'lodash';
import { map, take } from 'rxjs/operators';
import * as moment from 'moment';

import { DataService } from '../data.service';
import { HeaderService } from '../services/header.service';
import { ConfirmationDialogService } from '../services';
import { CanComponentDeactivate } from '../models/can-component-deactivate.interface';
import { ICroppedImage } from '../models/croppedImage';

enum TABS {
  INFO = 'INFO',
  COMMENTS = 'COMMENTS'
}

@Component({
  selector: 'app-create-pray',
  templateUrl: './create-pray.component.html',
  styleUrls: ['./create-pray.component.scss']
})
export class CreatePrayComponent implements OnInit, OnDestroy, AfterViewInit, CanComponentDeactivate {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  editorContent = '';
  title = '';
  cropperIsOpen = false;
  id: number;
  create: boolean;
  btndisabled = false;
  currentMoment: any;
  selectedDate: any;
  public TABS: typeof TABS = TABS;
  public activeTab: TABS;
  public form: FormGroup;
  private subs: Subscription;
  private formDataSnapshot: any;

  @ViewChild('formRef', {static: true}) formRef;

  @HostListener('window:beforeunload', [ '$event' ])
  onbeforeunload() {
    return !this.isChangesExists();
  }

  constructor(
    private readonly dataService: DataService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly headerService: HeaderService,
    private readonly confirmationDialogService: ConfirmationDialogService,
  ) {}

  ngOnInit() {
    this.subs = new Subscription();

    this.dataService.changeSideNav(true);
    this.id = this.route.snapshot.params.id;
    this.currentMoment = new Date();
    if (this.id) {
      this.dataService.getPray(this.id).subscribe(
        (result: any) => {
          this.title = result.title;
          this.editorContent = result.story;
          if (result.schedule_date) {
            this.selectedDate = new Date(result.schedule_date);
          } else {
            this.selectedDate = null;
          }
          this.croppedImage = result.image;
          this.form.patchValue(result);
          this.formDataSnapshot = { ...this.form.value };
        },
        () => {
          this.router.navigateByUrl('/prayers');
        }
      );
    } else {
      this.create = true;
      this.title = '';
      this.editorContent = '';
      this.croppedImage = '';
    }

    this.subs.add(
      this.headerService.saveObservable.subscribe(() => {
        this.onSubmit(this.form);
      })
    );
    this.subs.add(
      this.route.queryParams.subscribe(query => {
        if (query['comment-id']) {
          this.changeTab(TABS.COMMENTS, 1);
        } else {
          this.changeTab(TABS.INFO, 0);
        }
      })
    );

    this.formInit();
    this.formDataSnapshot = { ...this.form.value };
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  formInit() {
    this.form = new FormGroup({
      title: new FormControl('', [Validators.required]),
      country: new FormControl(null),
      story: new FormControl(''),
      image: new FormControl('', [Validators.required]),
      schedule_date: new FormControl(''),
      preview_story: new FormControl('', [Validators.required]),
      video_link: new FormControl(''),
      is_urgent: new FormControl(false),
    });
  }

  ngAfterViewInit(): void {
    const inputs = document.querySelectorAll('.mdc-text-field');
    inputs.forEach(input => {
      return new MDCTextField(input);
    });
  }

  triggerClick(elem) {
    elem.click();
  }

  cropImage(elem) {
    this.imageChangedEvent = null;
    this.cropperIsOpen = false;

    elem.value = '';
  }

  fileChangeEvent(event: any): void {
    if (!event.target.files.length) {
      return;
    }
    // 32MB
    if (event.target.files[0].size > 32000000) {
      return this.dataService.showNotification(
        'Picture is too big, try an image with size less than 32MB',
        null,
        4000,
        'success');

    }
    this.imageChangedEvent = event;
  }

  imageCropped(image: ICroppedImage) {
    this.croppedImage = image.base64;
  }

  imageLoaded() {
    this.cropperIsOpen = true;
  }

  setCurrentMoment() {
    this.currentMoment = new Date();
  }

  onSubmit(form) {
    this.form.markAllAsTouched();

    this.form.patchValue({
      image: this.croppedImage
    });

    const datePickerMinDateError = this.form.get('schedule_date').errors && this.form.get('schedule_date').errors.owlDateTimeMin;
    const isTimeTravel = datePickerMinDateError && this.id;

    if (this.form.invalid && !isTimeTravel) {
      return;
    }

    form = this.form;
    const convertedData = new FormData();
    if (this.form.value.schedule_date) {
      const scheduleDate = moment(form.value.schedule_date);
      convertedData.append('schedule_date', scheduleDate.toISOString());
    } else {
      convertedData.append('schedule_date', null);
    }

    convertedData.append('video_link', form.value.video_link || null);
    convertedData.append('story', form.value.story);
    convertedData.append('title', form.value.title);
    convertedData.append('preview_story', form.value.preview_story);
    convertedData.append('country', form.value.country);
    convertedData.append('is_urgent', form.value.is_urgent);
    this.btndisabled = true;
    const base64Rejex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
    const isBase64Valid = base64Rejex.test(form.value.image);
    if (isBase64Valid) {
      let convertedImage = form.value.image;
      const dataArray = convertedImage.match(/^data:(.*);base64,(.*)$/);
      convertedImage = this.b64toBlob(dataArray[2], dataArray[1]);
      convertedData.append('image', convertedImage);
    }

    this.headerService.blockSaving();

    if (this.create) {
      this.dataService.addPrayRequest(convertedData).subscribe(
        () => {
          this.formDataSnapshot = { ...this.form.value };
          this.router.navigateByUrl('/prayers').then(() => {
            this.headerService.unblockSaving();
          });
          this.dataService.showNotification('Prayer request has been added successfully!', null, 4000, 'success');
        },
        () => {
          this.headerService.unblockSaving();
        }
      );
    } else {
      this.dataService.updatePrayRequest(convertedData, this.id).subscribe(
        () => {
          this.formDataSnapshot = { ...this.form.value };
          this.router.navigateByUrl('/prayers').then(() => {
            this.headerService.unblockSaving();
          });
          this.dataService.showNotification('Prayer request has been changed successfully!', null, 4000, 'success');
        },
        () => {
          this.headerService.unblockSaving();
        }
      );
    }
  }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {

      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  changeTab(tab: TABS, index: number): void {
    this.activeTab = tab;
    setTimeout(() => {
      new MDCTabBar(document.querySelector(`.mdc-tab-bar`)).activateTab(index);
    }, 0);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isChangesExists()) {
      return this.confirmationDialogService.canDiactivateComponentDialog()
        .afterClosed()
        .pipe(
          map((response: { isAccept: boolean }) => {
            return response ? response.isAccept : false;
          }),
          take(1)
        );
    }
    return true;
  }

  private isChangesExists(): boolean {
    return !isEqual(this.formDataSnapshot, this.form.value);
  }
}
