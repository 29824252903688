import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { clone, findIndex } from 'lodash';
import * as queryString from 'querystring';

import { Comment } from '../models/comment';
import { environment } from '../../environments/environment';
import { FilterService } from './filter.service';
import { BaseListService } from '../base-list-component/base-list.service';
import { Prayer } from '../models/prayer';

@Injectable({
  providedIn: 'root'
})
export class CommentsService extends BaseListService {

  constructor(private http: HttpClient,
              private readonly filterService: FilterService) {
    super();
  }

  /**
   * Is used for initial GET of comments(prayers)
   * @param search search value
   */
  public get(search = ''): Observable<Comment[]> {
    const query = queryString.stringify({
      limit: this.limit,
      offset: this.offset,
      dateFrom: this.filterService.activeDateFilterOptions.dateFrom,
      dateTo: this.filterService.activeDateFilterOptions.dateTo,
      search: search,
      status: this.filterService.activeStatusFilters
    });
    return this.http.get(`${environment.API}/prayer/comment/?${query}`)
      .pipe(
        map((response: any) => {
          this._data.total = response.total;
          this._data.list = [...this._data.list, ...response.data];

          this._list.next(clone(this._data.list));
          return clone(this._data.list);
        })
      );
  }

  /**
   * GET comments for a specific prayer request
   * @param prayerId
   */
  getByPrayerId(prayerId: Prayer['id']): Observable<Comment[]> {
    const query = queryString.stringify({
      limit: 999,
      offset: this.offset,
    });

    return this.http.get(`${environment.API}/prayer/${prayerId}/comment/?${query}`)
      .pipe(
        map((response: any) => {
          this._data.total = response.total;
          this._data.list = [...this._data.list, ...response.data];

          this._list.next(clone(this._data.list));
          return clone(this._data.list);
        })
      );
  }

  /**
   * Is used for search from header search field
   * @param search
   */
  public search(search): Observable<Comment[]> {
    const query = queryString.stringify({
      limit: this.limit + this.offset,
      offset: 0,
      dateFrom: this.filterService.activeDateFilterOptions.dateFrom,
      dateTo: this.filterService.activeDateFilterOptions.dateTo,
      search: search,
      status: this.filterService.activeStatusFilters
    });
    return this.http.get(`${environment.API}/prayer/comment/?${query}`)
      .pipe(
        map((response: any) => {
          this._data.total = response.total;
          this._data.list = response.data;

          this._list.next(clone(this._data.list));
          return clone(this._data.list);
        })
      );
  }

  /**
   * Not actually removes comment, but changes status to blocked
   * @param id - comment id
   */
  public remove(id: number): Observable<any> {
    return this.http.patch(`${environment.API}/prayer/comment/${id}/`, {status: 'blocked'})
      .pipe(
        map((response: any) => {
          const index = findIndex(this._data.list, comment => comment.id === id);
          this._data.list[index] = response;

          this._list.next(clone(this._data.list));
        })
      );
  }

  /**
   * Change status to posted
   * @param id - comment id
   */
  public postComment(id: number): Observable<any> {
    return this.http.patch(`${environment.API}/prayer/comment/${id}/`, {status: 'posted'})
      .pipe(
        map((response: any) => {
          const index = findIndex(this._data.list, comment => comment.id === id);
          this._data.list[index] = response;

          this._list.next(clone(this._data.list));
        })
      );
  }


}
