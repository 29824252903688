import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  sideNavStatus = new Subject();
  userNotification = new Subject();
  prayRequestsList = new Subject();
  filteredPrayRequests = new Subject();

  API = environment.API;

  constructor(private router: Router, private http: HttpClient) { }

  changeSideNav(status: boolean) {
    this.sideNavStatus.next(status);
    return this.sideNavStatus.asObservable();
  }

  showNotification(message: string, action: string, durationVal: number, additionalClass) {
    this.userNotification.next({
      notifyMessage: message,
      notifyAction: action,
      notifyDuration: durationVal,
      class: additionalClass
    });
  }

  addPrayRequest(data) {
    const accessToken = sessionStorage.getItem('accessToken');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + accessToken
    });
    return this.http.post(this.API + '/prayers/', data, {headers});
  }

  updatePrayRequest(data, id) {
    const accessToken = sessionStorage.getItem('accessToken');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + accessToken
    });
    return this.http.put(this.API + '/prayers/' + id + '/', data, { headers});
  }

  getPrayRequests() {
    const accessToken = sessionStorage.getItem('accessToken');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });
    this.http.get(this.API + '/prayers/', {headers}).subscribe(
      (result) => this.prayRequestsList.next(result),
      (err: HttpErrorResponse) => {
        console.log('Error', err);
        if (err instanceof Error) {
          console.log('Frontend error');
          this.showNotification('Unfortunately, there was a problem. Please try again. If the problem continues, get in touch with your developers.', null, 4000, 'error');
          return null;
        } else {
          console.log('Backend returned error code ', err.status, '. Error body:', err.message);
          if (err.status === 401) {
            this.logOut('You have been logged out. Please input your credentials.');
            return null;
          } else {
            this.showNotification('We\'re experiencing some technical difficulties. Please check your internet connection or try again later.', null, 4000, 'error');
            return null;
          }
        }
      }
    );
  }

  logOut(message: string = 'You have been logged out.') {
    this.router.navigateByUrl('/authorization').then((result: boolean) => {
      if (!result) {
        return;
      }
      try {
        localStorage.removeItem('refreshToken');
      } catch (err) {
        console.log(err);
        this.showNotification('Critical error: ' + err, null, 4000, 'error');
      }
      try {
        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('accessToken');
      } catch (err) {
        console.log(err);
        this.showNotification('Critical error: ' + err, null, 4000, 'error');
      }
      this.showNotification(message, null, 4000, 'warning');
    });
  }

  deletePray(id: number) {
    const accessToken = sessionStorage.getItem('accessToken');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });
    this.http.delete(this.API + '/prayers/' + id + '/', {headers}).subscribe(
      (result) => {
        this.getPrayRequests();
        this.showNotification('Pray request was deleted successfully.', null, 4000, 'success');
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.showNotification('Critical error happens. Error: ' + err, null, 6000, 'error');
      }
    );
  }

  getPray(id: number) {
    const accessToken = sessionStorage.getItem('accessToken');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });
    return this.http.get(this.API + '/prayers/' + id + '/', {headers});
  }

  changePriority(id: number) {
    const accessToken = sessionStorage.getItem('accessToken');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });
    this.http.put(this.API + '/change_priority/' + id + '/', null, {headers}).subscribe(
      (result) => {
        this.getPrayRequests();
        this.showNotification('Pray request\'s priority was successfully changed.', null, 4000, 'success');
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.showNotification('Critical error happens. Error status: ' + err.status, null, 6000, 'error');
      }
    );
  }

  filterPrayRequests(value: number) {
    this.filteredPrayRequests.next(value);
  }

  getEmails() {
    const accessToken = sessionStorage.getItem('accessToken');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + accessToken
    });
    this.http.get(this.API + '/emails/', {headers, responseType: 'blob'}).subscribe(
      (result) => {
        const blob = new Blob([result], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'open_doors_clients_emails.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.showNotification('E-mails exported successfully.', null, 4000, 'success');
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.showNotification('Critical error happens. Error status: ' + err.status, null, 6000, 'error');
      }
    );
  }

  getPrivacyPolicy() {
    const accessToken = sessionStorage.getItem('accessToken');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + accessToken
    });
    return this.http.get(this.API + '/privacy-policy/', {headers});
  }

  getTermsOfService() {
    const accessToken = sessionStorage.getItem('accessToken');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + accessToken
    });
    return this.http.get(this.API + '/terms-of-service/', {headers});
  }

  updateLegal(name, content) {
    const accessToken = sessionStorage.getItem('accessToken');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + accessToken
    });
    const type = name === 'Privacy Policy' ? 'privacy-policy' : 'terms-of-service';
    const data = {
      'type': type,
      'text': content
    };
    return this.http.post(this.API + '/docs/', data, {headers});
  }

}
