import { AfterViewInit, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { HeaderService } from '../services/header.service';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(headerService) {
        this.headerService = headerService;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subs = new Subscription();
        this.searchForm = new FormControl('');
        this.subs.add(this.searchForm.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(function (value) {
            if (/^(\/prayers)$/.test(location.pathname)) {
                _this.headerService.searchValue = value;
            }
            else if (/^(\/comments)$/.test(location.pathname)) {
                _this.headerService.commentSearch = value;
            }
            else if (/^(\/flagged-expressions)$/.test(location.pathname)) {
                _this.headerService.expressionSearch = value;
            }
        }));
    };
    HeaderComponent.prototype.ngAfterViewInit = function () {
    };
    HeaderComponent.prototype.saveHandler = function () {
        this.headerService.save();
    };
    Object.defineProperty(HeaderComponent.prototype, "showCreate", {
        // todo make a service which will control behavior of header components
        get: function () {
            return /^(\/prayers)$/.test(location.pathname);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "showSave", {
        get: function () {
            return /^(\/links|\/prayers\/new|\/prayers\/edit\/[0-9]+|\/flagged-expressions\/new)$/.test(location.pathname);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "showSearch", {
        get: function () {
            return /^(\/prayers|\/comments|\/flagged-expressions)$/.test(location.pathname);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "showBtnBack", {
        get: function () {
            return !/^(\/links|\/prayers|\/comments|\/flagged-expressions)$/.test(location.pathname);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "showComments", {
        get: function () {
            return /^(\/comments)$/.test(location.pathname);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "showCreateExpression", {
        get: function () {
            return /^(\/flagged-expressions)$/.test(location.pathname);
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.exportComments = function () {
        this.headerService.exportComments();
    };
    HeaderComponent.prototype.back = function () {
        window.history.back();
    };
    return HeaderComponent;
}());
export { HeaderComponent };
